import {
  INIT_LOADING_APP,
  END_LOADING_APP,
  INIT_PARAMS,
  ERROR,
  SET_WORKNAME,
  SET_SHOW_MODAL,
  GET_FORMAT_DETAIL,
  GET_TEMPLATE_DETAIL,
  POST_SAVE_TEMPLATE,
  GET_TEMPLATE_DETAIL_TO_UPDATE_PAGE,
  GET_TEMPLATE_DETAIL_TO_ADD_PAGE,
  GET_PAGE_DETAIL,
  SET_MULTIPAGE,
  SET_TEMPLATE,
  DO_API_CLOSE_WINDOW,
  SET_PAGE_CONTROLS_ENABLED,
  SET_SELECTED_FORMAT_AND_TYPE,
  CLIENT_MODE,
  SET_PERMISSION,
} from "./type";
import settings from "../../config/setting";
const initState = {
  loadingApp: true,
  tokenDefinitivo: null,
  actionToDo: null,
  id_client: 10,
  id_signboard: null,
  id_format: null,
  formatName: "",
  id_template: null,
  id_template_type: null,
  tab_id: null,
  error: null,
  is_multipage: false,
  permissionPack: null,
  workname: "",
  notShowModal: false,
  page_width: null,
  page_height: null,
  loadedJson: null,
  pageIndex: null,
  demo: false,
  redirect_url: null,
  auth_api_url: settings.INTERATTIVO_URL,
  has_works: false,
  popoverOpen: false,
  doApiCloseWindow: true,
  pageControlsEnabled: true,
  selectedFormat: null,
  selectedType: null,
  platform: settings.PLATFORMS.INPUBLISH,
  permission: {
    canAddPage: false,
    canDuplicatePage: false,
  },
  tag_flat_list: "",
  update_banner_image: false,
};
export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case INIT_LOADING_APP:
      return {
        ...state,
        loadingApp: true,
      };
    case END_LOADING_APP:
      return {
        ...state,
        loadingApp: false,
      };
    case CLIENT_MODE:
      return {
        ...state,
        id_client: action.payload.params.id_client,
        actionToDo: action.payload.params.action,
        id_format: action.payload.params.id_format,
      };
    case INIT_PARAMS:
      return {
        ...state,
        tokenDefinitivo: action.payload.token,
        actionToDo: action.payload.params.action,
        id_client: action.payload.params.id_client,
        id_signboard: action.payload.params.id_signboard,
        id_format: action.payload.params.id_format,
        id_template: action.payload.params.id_template,
        tab_id: action.payload.params.tab_id,
        is_multipage: action.payload.params.is_multipage,
        demo:
          "demo" in action.payload.params ? action.payload.params.demo : false,
        permissionPack:
          "permission_pack" in action.payload.params
            ? action.payload.params.permission_pack
            : null,
        workname:
          "workname" in action.payload.params
            ? action.payload.params.workname
            : action.payload.params.interactive_flyer_name,
        pageIndex:
          "page_index" in action.payload.params
            ? action.payload.params.page_index
            : null,
        formatName: action.payload.params.formatName,
        redirect_url: action.payload.params.redirect_url,
        auth_api_url:
          action.platform == settings.PLATFORMS.INPUBLISH
            ? settings.INTERATTIVO_URL
            : settings.FAIDATE_URL,
        has_works: action.payload.params.has_works,
        tag_flat_list: action.payload.params.tag_flat_list,
        update_banner_image: action.payload.params.update_banner_image,
      };
    case GET_FORMAT_DETAIL:
      return {
        ...state,
        is_multipage: action.payload.data.is_multipage,
        id_template_type: action.payload.data.id_template_type,
        page_height: action.payload.data.height,
        page_width: action.payload.data.width,
        workname: `${state.workname}_${action.payload.data.name}`,
        formatName: `${action.payload.data.name} (${action.payload.data.width} x ${action.payload.data.height})`,
      };
    case GET_TEMPLATE_DETAIL:
      return {
        ...state,
        is_multipage: action.payload.data.format_page.is_multipage,
        workname: action.payload.data.name,
        loadedJson: action.payload.data.json_format,
        page_height: action.payload.data.format_page.height,
        page_width: action.payload.data.format_page.width,
        formatName: `${action.payload.data.formatPage.name} (${action.payload.data.formatPage.width} x ${action.payload.data.formatPage.height})`,
      };
    case GET_TEMPLATE_DETAIL_TO_UPDATE_PAGE:
      return {
        ...state,
        is_multipage: false,
        workname:
          state.pageIndex === 0
            ? action.payload.data.name + "_last_page"
            : action.payload.data.name + "_page_" + state.pageIndex,
        page_height: action.payload.data.format_page.height,
        page_width: action.payload.data.format_page.width,
        loadedJson: action.payload.data.json_format,
        formatName: `${action.payload.data.format_page.name} (${action.payload.data.format_page.width} x ${action.payload.data.format_page.height})`,
      };
    case GET_PAGE_DETAIL:
      return {
        ...state,
        is_multipage: false,
        workname:
          state.pageIndex === 0
            ? action.payload.name + "_last_page"
            : action.payload.name + "_page_" + state.pageIndex,
        page_height: action.payload.format_page.height,
        page_width: action.payload.format_page.width,
        loadedJson: action.payload.json_format,
        formatName: `${action.payload.format_page.name} (${action.payload.format_page.width} x ${action.payload.format_page.height})`,
      };
    case GET_TEMPLATE_DETAIL_TO_ADD_PAGE:
      return {
        ...state,
        is_multipage: false,
        page_height: action.payload.data.format_page.height,
        page_width: action.payload.data.format_page.width,
        loadedJson: action.payload.data.json_format,
        workname:
          state.pageIndex === 0
            ? action.payload.data.name + "_last_page"
            : action.payload.data.name + "_page_" + state.pageIndex,
        formatName: `${action.payload.data.format_page.name} (${action.payload.data.format_page.width} x ${action.payload.data.format_page.height})`,
      };
    case POST_SAVE_TEMPLATE:
      return {
        ...state,
        id_template: action.payload.data.id,
        actionToDo: state.actionToDo === "client-mode" ? "client-mode" : "upd",
        loadingApp: false,
      };
    case SET_WORKNAME:
      return {
        ...state,
        workname: action.payload,
        popoverOpen: true,
      };
    case SET_SHOW_MODAL:
      return {
        ...state,
        notShowModal: action.payload,
      };
    case SET_MULTIPAGE:
      return {
        ...state,
        is_multipage: action.payload,
      };
    case SET_TEMPLATE:
      return {
        ...state,
        id_template: action.payload.id,
        id_format: action.payload.format_page.id,
        formatName: action.payload.format_page.name,
      };
    case DO_API_CLOSE_WINDOW:
      return {
        ...state,
        doApiCloseWindow: action.payload,
      };
    case SET_PAGE_CONTROLS_ENABLED:
      return {
        ...state,
        pageControlsEnabled: action.payload,
      };
    case SET_SELECTED_FORMAT_AND_TYPE:
      return {
        ...state,
        id_format: action.payload.id,
        id_template_type: action.payload.templateType,
        formatName: `${action.payload.name} (${action.payload.width} x ${action.payload.height})`,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_PERMISSION:
      return {
        ...state,
        permission: {
          ...state.permission,
          canAddPage: action.payload,
          canDuplicatePage: action.payload,
        },
      };
    default:
      return state;
  }
}
