import { Checkbox } from "@blueprintjs/core";
import { observer } from 'mobx-react-lite';
import { Alert } from "@blueprintjs/core";
export const CustomAlert = observer(({ store, message, cancelButtonText, confirmButtonText, icon, intent, isOpen, isLoading, handleCancel, handleConfirm, checkboxValue, checkbox, checkboxText, onChangeValueCheckbox }) => {

    return (
        <Alert
            // className={this.props.data.themeName}
            cancelButtonText={cancelButtonText}
            confirmButtonText={confirmButtonText}
            icon={icon}
            intent={intent}
            isOpen={isOpen}
            loading={isLoading}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        >
            <p>
                {message}
            </p>
            {checkbox && <p>
                <Checkbox checked={checkboxValue} label={checkboxText} onChange={onChangeValueCheckbox} />
            </p>}
        </Alert>
    )
})