import {
  LOAD_IMAGES,
  LOAD_CATEGORIES,
  SET_SUB_CATEGORIES,
  ERROR,
  INIT_LOADING_IMAGES,
  END_LOADING_IMAGES,
  SET_CATEGORY,
  LOAD_ELEMENTS,
} from "./type";
const initState = {
  images: [],
  allCategories: [],
  primaryCategories: [],
  subCategories: [],
  elements: [],
  loadingElements: true,
  loadingImages: true,
  error: null,
  pagination: {
    active: 1,
    last: 20,
  },
  page: 1,
  pagesize: 16,
  paginationElements: {
    active: 1,
    last: 20,
  },
  pageElements: 1,
  pagesizeElements: 9,
  term: "",
  id_category: null,
  hasMore: true,
};
export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case LOAD_IMAGES:
      var app_images = action.payload.response;
      const new_images = action.payload.n_page === 1 ? [] : [...state.images]; // Se è la prima pagina, resetta le immagini, altrimenti mantieni quelle esistenti
      app_images?.data.items.map((item) => {
        return new_images.push({
          url: item.primary_image,
          id: item.id,
          description_1: item.description_1,
          description_2: item.description_2,
          description_3: item.description_3,
          description_4: item.description_4,
          price: item.price,
          offer_price: item.offer_price,
        });
      });
      return {
        ...state,
        images: new_images,
        pagination: {
          ...state.pagination,
          active: action.payload.n_page,
          last: action.payload.response.data.pages,
        },
        pagesize: action.payload.page_size,
        term: action.payload.term,
        loadingImages: false,
        hasMore: action.payload.hasMore,
      };
    case LOAD_CATEGORIES:
      var app_categories = action.payload.response.data.categorie;
      var new_categories = [];
      app_categories.map((item) => {
        if (item.primary === 1) new_categories.push(item);
      });
      return {
        ...state,
        primaryCategories: new_categories,
        allCategories: action.payload.response.data.categorie,
      };
    case LOAD_ELEMENTS:
      return {
        ...state,
        elements: action.payload.response.data,
        paginationElements: {
          ...state.paginationElements,
          active: action.payload.n_page,
          last: action.payload.response.pages,
        },
        pagesizeElements: action.payload.page_size,
        loadingElements: false,
      };
    case SET_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload.subCategories,
        id_category: action.payload.category,
      };
    case SET_CATEGORY:
      return {
        ...state,
        id_category: action.payload.category,
      };
    case INIT_LOADING_IMAGES:
      return {
        ...state,
        loadingImages: true,
      };
    case END_LOADING_IMAGES:
      return {
        ...state,
        loadingImages: false,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
