import {
  GET_CLIENT_TEMPLATES,
  GET_CLIENT_MODELS,
  INIT_LOADING_CLIENT_TEMPLATES,
  END_LOADING_CLIENT_TEMPLATES,
  INIT_LOADING_CLIENT_MODELS,
  END_LOADING_CLIENT_MODELS,
  INIT_REFRESH_CLIENT_MODELS,
  END_REFRESH_CLIENT_MODELS,
  INIT_REFRESH_CLIENT_TEMPLATES,
  END_REFRESH_CLIENT_TEMPLATES,
} from "./type";
import settings from "../../config/setting";

export const loadModels =
  (id_client, id_format, pagesize, page, pagination, term) => (dispatch) => {
    dispatch({
      type: INIT_LOADING_CLIENT_MODELS,
    });
    var url = settings.SERVER_URL + "get-client-models/";
    var data = new FormData();
    data.append("term", term);
    data.append("id_client", id_client);
    id_format && data.append("id_format", id_format);
    data.append("limit", pagesize);
    data.append("page", page);
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GET_CLIENT_MODELS,
          payload: {
            response,
            page,
            pagesize,
            pagination,
          },
        });
        dispatch({
          type: END_LOADING_CLIENT_MODELS,
        });
        dispatch({
          type: END_REFRESH_CLIENT_MODELS,
        });
      });
  };

export const loadTemplates =
  (id_client, id_format, pagesize, page, pagination, term) => (dispatch) => {
    dispatch({
      type: INIT_LOADING_CLIENT_TEMPLATES,
    });
    var url = settings.SERVER_URL + "get-client-templates/";
    var data = new FormData();
    data.append("term", term);
    data.append("id_client", id_client);
    id_format && data.append("id_format", id_format);
    data.append("limit", pagesize);
    data.append("page", page);
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GET_CLIENT_TEMPLATES,
          payload: {
            response,
            page,
            pagesize,
            pagination,
          },
        });
        dispatch({
          type: END_LOADING_CLIENT_TEMPLATES,
        });
        dispatch({
          type: END_REFRESH_CLIENT_TEMPLATES,
        });
      });
  };

export const setRefreshModels = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_REFRESH_CLIENT_MODELS,
    });
  } else {
    dispatch({
      type: END_REFRESH_CLIENT_MODELS,
    });
  }
};

export const setRefreshTemplates = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_REFRESH_CLIENT_TEMPLATES,
    });
  } else {
    dispatch({
      type: END_REFRESH_CLIENT_TEMPLATES,
    });
  }
};
