import {
  LOAD_IMAGES,
  INIT_LOADING_IMAGES,
  END_LOADING_IMAGES,
  LOAD_CATEGORIES,
  SET_CATEGORY,
  SET_SUB_CATEGORIES,
  LOAD_ELEMENTS,
} from "./type";
import settings from "../../config/setting";

export const loadImages = (id_client, id_signboard, page_size, n_page, term, id_category = -1) => async (dispatch) => {
  var data = new FormData();
  data.append("id_client", id_client);
  data.append("id_signboard", id_signboard);
  data.append("pagesize", page_size);
  data.append("page", n_page);
  data.append("term", term);
  data.append("id_category", id_category);

  try {
    const response = await fetch(`${settings.URL_PORTAL}get-products`, {
      method: "POST",
      body: data,
    }).then((res) => res.json());

    dispatch({
      type: LOAD_IMAGES,
      payload: {
        response,
        id_client,
        id_signboard,
        page_size,
        n_page,
        term,
        hasMore: response.data.pages > n_page // Determina se ci sono più pagine da caricare
      },
    });
  } catch (error) {
    console.error("Errore nel caricamento delle immagini: ", error);
  }
};


export const loadCategories = (id_client, id_signboard) => (dispatch) => {
  var data = new FormData();
  data.append("id_client", id_client);
  data.append("id_signboard", id_signboard);
  fetch(`${settings.URL_PORTAL}get-categories`, {
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      dispatch({
        type: LOAD_CATEGORIES,
        payload: {
          response,
          id_client,
          id_signboard,
        },
      });
    });
};

export const loadElements = (page_size, n_page) => (dispatch) => {
  var data = new FormData();
  data.append("page", n_page);
  data.append("limit", page_size);
  fetch(`${settings.SERVER_URL}get-elements/`, {
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      dispatch({
        type: LOAD_ELEMENTS,
        payload: {
          response,
          page_size,
          n_page,
        },
      });
    });
};

export const loadSubCategories = (subCategories, category) => (dispatch) => {
  dispatch({
    type: SET_SUB_CATEGORIES,
    payload: {
      subCategories,
      category,
    },
  });
};

export const setCategory = (category) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY,
    payload: {
      category,
    },
  });
};

export const setLoading = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_LOADING_IMAGES,
    });
  } else {
    dispatch({
      type: END_LOADING_IMAGES,
    });
  }
};
