import {
    INIT_LOADING_FORMAT,
    END_LOADING_FORMAT,
    INIT_LOADING_TYPES,
    END_LOADING_TYPES,
    GET_TEMPLATE_TYPE_LIST,
    GET_FORMAT_LIST,
    ERROR,
} from './type'
const initState = {
    loadingFormat: true,
    loadingTypes: true,
    formats: [],
    types: []
}
export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case GET_TEMPLATE_TYPE_LIST:
            return {
                ...state,
                types: action.payload.data,
                loadingTypes: false
            }
        case GET_FORMAT_LIST:
            return {
                ...state,
                formats: action.payload.data,
                loadingFormat: false
            }
        case INIT_LOADING_TYPES:
            return {
                ...state,
                loadingTypes: true
            }
        case END_LOADING_TYPES:
            return {
                ...state,
                loadingTypes: false
            }
        case INIT_LOADING_FORMAT:
            return {
                ...state,
                loadingFormat: true
            }
        case END_LOADING_FORMAT:
            return {
                ...state,
                loadingFormat: false
            }
        case ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}