export const style = {
    container: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        justifyContent: "center",
        alignItems: "center"
    },
    imageContainer: {
        width: "90%",
        margin: '5%',
        boxShadow: "0 0 5px rgb(16 22 26 / 30%)",
        borderRadius: 5,
        padding:10
    },
    image: {
        width: "100%",
        height: "auto",
        cursor: "pointer",
        display: "block",
        borderRadius: 5
    },
    title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 16,
        paddingTop:10,
        fontWeight: "600"
    }
}