import { ERROR, SAVE_WORK } from "./type";

export const save =
  (url, body) =>
  (callback = null) =>
  (dispatch) => {
    fetch(url, { method: "POST", body: body })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: SAVE_WORK,
            payload: response,
          });
          callback(response);
        } else {
          dispatch({
            type: ERROR,
            payload: response.error,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ERROR,
          payload: error,
        });
      });
  };
