import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Workspace } from "polotno/canvas/workspace";
import { DEFAULT_SECTIONS, PhotosSection, SidePanel } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { createStore } from "polotno/model/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  TextSection,
  ElementsSection,
  UploadSection,
  BackgroundSection,
  LayersSection,
} from "polotno/side-panel";
import { SpinnerSize, Spinner, Icon } from "@blueprintjs/core";
import { SectionTab } from "polotno/side-panel";
import MdcShopping from "@meronex/icons/mdc/MdcShopping";
import settings from "./config/setting";
import { InterattivoPanel } from "./Panels/InterattivoPanel";
import { PolotnoTemplate } from "./Panels/PolotnoTemplate";
import { ProdottiPanel } from "./Panels/ProdottiPanel";
import { ClientWorksPanel } from "./Panels/ClientWorksPanel";
import { ClientModelsPanel } from "./Panels/ClientModelsPanel";
import { PageControls } from "./Panels/PageControls";
import { FormatPanel } from "./Panels/FormatPanel";
import { SaveComponent } from "./components/SaveComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  authForDemo,
  authWithToken,
  getFormatDetail,
  getTemplateDetail,
  setLoadingApp,
  getTemplateDetailToUpdatePage,
  getTemplateDetailToAddPage,
  getPageDetailToUpdatePage,
  setMultipage,
  setPageControlsEnabled,
  activateClientMode,
  setPermission,
} from "./redux/auth/action";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import { MyDrawer } from "./components/Drawer";
import interattivoIcon from "./logo192.png";
import { AppVersion } from "./components/AppVersion";
import { Error } from "./components/Error";
import { getTemplateTypeList } from "./redux/formatReducer/action";
import moment from "moment";
import { translation } from "./translation/translation";
import { UploadPanel } from "./Panels/UploadPanel";
const store = createStore({
  key: settings.POLOTNO_KEY,
  showCredit: false,
});
store.addPage();
store.setUnit({
  unit: "px", // mm, cm, in, pt, px
});

const resizePage = (w, h) => {
  store.setSize(parseFloat(w), parseFloat(h), true);
};

const UploadSectionCustom = DEFAULT_SECTIONS.find(
  (section) => section.name === "upload"
);
// overwrite its panel component
UploadSectionCustom.Tab = (props) => (
  <SectionTab name="Caricamenti" {...props}>
    <Icon icon="media" size={16} />
  </SectionTab>
);
UploadSectionCustom.Panel = UploadPanel;

const ProdottiSection = {
  name: "Photos",
  Tab: (props) => (
    <SectionTab name="Prodotti" {...props}>
      <MdcShopping style={{ fontSize: 20 }} />
    </SectionTab>
  ),
  Panel: ProdottiPanel,
};

const ClientModelSection = {
  name: "Models",
  Tab: (props) => (
    <SectionTab name="Modelli" {...props}>
      <Icon icon="folder-close" size={16} />
    </SectionTab>
  ),
  Panel: ClientModelsPanel,
};

const ClientWorksSection = {
  name: "Works",
  Tab: (props) => (
    <SectionTab name="Lavori" {...props}>
      <Icon icon="projects" size={16} />
    </SectionTab>
  ),
  Panel: ClientWorksPanel,
};

const InterattivoSection = {
  name: "DefaultTemplates",
  Tab: (props) => (
    <SectionTab name="Template" {...props}>
      {/* <img
        src={interattivoIcon}
        style={{ height: 25, width: 25 }}
        alt="control"
      /> */}
      <Icon icon="control" size={16} />
    </SectionTab>
  ),
  Panel: InterattivoPanel,
};

// const FormatSection = {
//   name: 'SizeSection',
//   Tab: (props) => (
//     <SectionTab name="Formati" {...props}>
//       <Icon icon="fullscreen" size={16} />
//     </SectionTab>
//   ),
//   Panel: SizeSection
// };

export const App = () => {
  const [section, setSection] = React.useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const {
    authReducer: {
      error,
      loadingApp,
      id_client,
      pageControlsEnabled,
      has_works,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const sessionValue = sessionStorage.getItem("shouldPromptBeforeUnload");

      if (sessionValue && JSON.parse(sessionValue) === true)
        event.returnValue = "Sei sicuro di voler lasciare la pagina?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    onProcess();
  }, []);

  const onProcess = () => {
    sessionStorage.setItem("shouldPromptBeforeUnload", JSON.stringify(true));
    store.openSidePanel("DefaultTemplates");
    var token = queryParams.get("token");
    var platform = queryParams.get("platform")
      ? queryParams.get("platform")
      : "inpublish";
    if (token === "badildemo") {
      initDemo();
      return;
    } else if (token === "clientmode") {
      var id_client = queryParams.get("id_client");
      var response = {};
      response.params = {};
      response.params.action = "client-mode";
      response.params.id_format = 13;
      response.params.id_client = id_client;
      checkAction(response);
      return;
    }
    dispatch(
      authWithToken(
        token,
        platform
      )((response) => {
        checkAction(response);
      })
    );
  };

  const initDemo = () => {
    dispatch(authForDemo());
    if (id_client) {
      setSection([
        InterattivoSection,
        PolotnoTemplate,
        ProdottiSection,
        UploadSection,
        ClientModelSection,
        ClientWorksSection,
        LayersSection,
        TextSection,
        PhotosSection,
        ElementsSection,
        BackgroundSection,
      ]);
    } else {
      setSection([
        InterattivoSection,
        PolotnoTemplate,
        ProdottiSection,
        UploadSection,
        LayersSection,
        TextSection,
        PhotosSection,
        ElementsSection,
        BackgroundSection,
      ]);
    }
    dispatch(getTemplateTypeList());
    dispatch(setPermission(true));
    dispatch(setPageControlsEnabled(true));
    dispatch(setLoadingApp(false));
  };

  const checkAction = (response) => {
    switch (response.params.action) {
      case "upd":
        dispatch(
          getTemplateDetail(response.params.id_template)((res) => {
            store.loadJSON(JSON.parse(res.data.json_format));
            res.data.format_page.is_multipage && dispatch(setPermission(true));
            setSection([
              InterattivoSection,
              ProdottiSection,
              UploadSection,
              ClientModelSection,
              ClientWorksSection,
              PhotosSection,
              LayersSection,
              TextSection,
              ElementsSection,
              BackgroundSection,
            ]);
          })
        );
        break;
      case "new":
        dispatch(
          getFormatDetail(response.params.id_format)((res) => {
            setSection([
              InterattivoSection,
              PolotnoTemplate,
              ProdottiSection,
              UploadSection,
              ClientModelSection,
              ClientWorksSection,
              PhotosSection,
              LayersSection,
              TextSection,
              ElementsSection,
              BackgroundSection,
            ]);
            resizePage(parseFloat(res.data.width), parseFloat(res.data.height));
            res.data.is_multipage && dispatch(setPermission(true));
          })
        );
        break;
      // case "update-page":
      //   dispatch(
      //     getTemplateDetailToUpdatePage(response.params.id_template)((res) => {
      //       console.log(res);
      //       var page_to_update = [
      //         JSON.parse(res.data.json_format).pages[
      //           parseInt(response.params.page_index) - 1
      //         ],
      //       ];
      //       console.log("page to up", typeof page_to_update, page_to_update);
      //       var json_to_load = JSON.parse(res.data.json_format);
      //       console.log("json_to_load", typeof json_to_load, json_to_load);
      //       json_to_load.pages = page_to_update;
      //       store.loadJSON(json_to_load);
      //       dispatch(setPermission(false));
      //       setSection([
      //         InterattivoSection,
      //         ProdottiSection,
      //         UploadSection,
      //         ClientModelSection,
      //         ClientWorksSection,
      //         PhotosSection,
      //         LayersSection,
      //         TextSection,
      //         ElementsSection,
      //         BackgroundSection,
      //       ]);
      //     })
      //   );
      //   break;
      case "update-page":
        var page_index = response.params.page_index - 1;
        dispatch(
          getPageDetailToUpdatePage(response.params.id_template)(page_index)(
            (res) => {
              store.loadJSON(res.data.json_format);
              dispatch(setPermission(false));
              setSection([
                InterattivoSection,
                ProdottiSection,
                UploadSection,
                ClientModelSection,
                ClientWorksSection,
                PhotosSection,
                LayersSection,
                TextSection,
                ElementsSection,
                BackgroundSection,
              ]);
            }
          )
        );
        break;
      case "create-page":
        dispatch(
          getTemplateDetailToAddPage(response.params.id_template)((res) => {
            dispatch(setPermission(false));
            resizePage(
              parseFloat(res.data.format_page.width),
              parseFloat(res.data.format_page.height)
            );
            setSection([
              InterattivoSection,
              ProdottiSection,
              UploadSection,
              ClientModelSection,
              ClientWorksSection,
              PhotosSection,
              LayersSection,
              TextSection,
              ElementsSection,
              BackgroundSection,
            ]);
          })
        );
        break;
      case "insert-page":
        dispatch(
          getFormatDetail(response.params.id_format)((res) => {
            dispatch(setMultipage(false));
            setSection([
              InterattivoSection,
              PolotnoTemplate,
              ProdottiSection,
              UploadSection,
              ClientModelSection,
              ClientWorksSection,
              PhotosSection,
              LayersSection,
              TextSection,
              ElementsSection,
              BackgroundSection,
            ]);
            resizePage(parseFloat(res.data.width), parseFloat(res.data.height));
            dispatch(setPermission(false));
          })
        );
        break;
      case "client-mode":
        dispatch(
          getFormatDetail(response.params.id_format)((res) => {
            dispatch(setMultipage(false));
            dispatch(setPermission(true));
            setSection([
              InterattivoSection,
              PolotnoTemplate,
              ProdottiSection,
              UploadSection,
              ClientModelSection,
              ClientWorksSection,
              PhotosSection,
              LayersSection,
              TextSection,
              ElementsSection,
              BackgroundSection,
            ]);
            resizePage(parseFloat(res.data.width), parseFloat(res.data.height));
            dispatch(activateClientMode(response));
            dispatch(getTemplateTypeList());
          })
        );
        break;
      default:
        return;
    }
    dispatch(getTemplateTypeList());
    dispatch(setLoadingApp(false));
  };

  const ActionControls = ({ store }) => {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "95%",
            marginRight: 10,
            color: "red",
            fontSize: 13,
            fontWeight: "600",
          }}
        >
          Salva il tuo lavoro per non perderlo
        </div>
        <SaveComponent store={store} />
      </div>
    );
  };

  if (error) {
    return <Error store={store} error={error} />;
  } else {
    return (
      <div>
        <ToastContainer limit={1} />
        {loadingApp && (
          <div
            style={{
              position: "fixed",
              display: "flex",
              zIndex: 2000,
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100%",
              backgroundColor: "#00000070",
            }}
          >
            <Spinner
              size={SpinnerSize.LARGE}
              color={"white"}
              intent={"primary"}
            />
          </div>
        )}
        <PolotnoContainer
          className="polotno-app-container"
          style={{ height: "100vh" }}
        >
          <SidePanelWrap>
            <SidePanel
              store={store}
              sections={
                !section
                  ? []
                  : has_works
                  ? section
                  : section.filter((el) => el.name !== "Works")
              }
              defaultSection="photos"
            />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} components={{ ActionControls }} />
            <Workspace
              store={store}
              pageControlsEnabled={pageControlsEnabled}
              components={{ PageControls }}
            />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
          <div id="modal-change-template"></div>
          <div id="modal-close-tab"></div>
          <AppVersion store={store} />
          <MyDrawer store={store} />
        </PolotnoContainer>
      </div>
    );
  }
};

export default App;
