import {
    INIT_LOADING_DRAWER,
    END_LOADING_DRAWER,
    SET_DRAWER_OPEN
} from './type'

export const setLoadingDrawer = (value) => dispatch => {
    if (value) {
        dispatch({
            type: INIT_LOADING_DRAWER
        })
    } else {
        dispatch({
            type: END_LOADING_DRAWER
        })
    }
}

export const setDrawerOpen = (value, type, title) => dispatch => {
    dispatch({
        type: SET_DRAWER_OPEN,
        payload: {
            value,
            type,
            title
        }
    })
}

