import { observer } from 'mobx-react-lite';
import { useDispatch, useSelector } from 'react-redux';
import { Button, SpinnerSize, Spinner } from "@blueprintjs/core";
import settings from '../config/setting';
import { MenuDivider, EditableText, Icon, Intent } from "@blueprintjs/core";

import { style } from './CustomImagesGrid.style'
import React from "react";
import { toast } from "react-toastify";
import { loadModels, loadTemplates } from '../redux/clientReducer/action';
import { CustomAlert } from "./CustomAlert";
import { setDrawerOpen } from '../redux/drawerReducer/action';
import { setSelectedTemplate } from '../redux/auth/action';

export const CustomImagesGrid = observer(({ store, data, type }) => {
    const { clientReducer: { loadingClientTemplates, loadingClientModels, pagination_m, pagesize_m, page_m, pagination_t, pagesize_t, page_t } } = useSelector(state => state)
    const { authReducer: { id_client, id_format } } = useSelector(state => state)
    const [newName, setNewName] = React.useState('')
    const [template, setTemplate] = React.useState({})
    const [alertOpen, setAlertOpen] = React.useState(false)
    const [alertLoading, setAlertLoading] = React.useState(false)
    const dispatch = useDispatch()
    const toastOption = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    const reload = () => {
        if (type === 'models')
            dispatch(loadModels(id_client, id_format, pagesize_m, page_m, pagination_m, ''));
        else
            dispatch(loadTemplates(id_client, id_format, pagesize_t, page_t, pagination_t, ''));
    }

    const deleteTemplate = async (id_template) => {
        var data = new FormData()
        data.append('id_template', id_template)
        data.append('no_models', false)
        try {
            var res = await fetch(`${settings.SERVER_URL}delete-template/`, { method: 'DELETE', body: data })
            if (res) {
                var responseJson = await res.json()
                if (responseJson.success) {
                    setAlertOpen(false)
                    setAlertLoading(false)
                    toast.success(responseJson.message, toastOption)
                    reload()
                } else {
                    setAlertOpen(false)
                    setAlertLoading(false)
                    toast.error(responseJson.message, toastOption)
                }
            }
        } catch (error) {
            toast.error(error, toastOption)
        }

    }

    const editNameTemplate = async (template) => {
        if (template.name !== newName) {
            var data = new FormData()
            data.append('id_template', template.id)
            data.append('name', newName)
            try {
                var res = await fetch(`${settings.SERVER_URL}update-name-template/`, { method: 'POST', body: data })
                if (res) {
                    var responseJson = await res.json()
                    if (responseJson.success) {
                        toast.success(responseJson.message, toastOption)
                        setNewName('')
                        reload()
                    } else {
                        toast.error(responseJson.message, toastOption)
                    }
                }
            } catch (error) {
                toast.error(error, toastOption)
            }
        }
    }

    const handleConfirmAlert = () => {
        setAlertLoading(true)
        setTimeout(() => {
            deleteTemplate(template?.id)
        }, 1000);
    }

    if (type === 'models' && loadingClientModels)
        return <Spinner size={SpinnerSize.LARGE} color={'white'} intent={'primary'} style={{ margin: 100 }} />
    else if (type === 'templates' && loadingClientTemplates)
        return <Spinner size={SpinnerSize.LARGE} color={'white'} intent={'primary'} style={{ margin: 100 }} />
    else {
        return (
            <div style={style.container}>
                <CustomAlert
                    store={store}
                    message={`Sei sicuro di voler eliminare il template? L'azione è irreversibile`}
                    isOpen={alertOpen}
                    isLoading={alertLoading}
                    confirmButtonText={'Elimina'}
                    cancelButtonText={'Chiudi'}
                    icon={'trash'}
                    intent={Intent.DANGER}
                    handleConfirm={handleConfirmAlert}
                    handleCancel={() => {
                        setAlertOpen(false);
                        setAlertLoading(false)
                    }}
                />
                {data.map((item) => {
                    return (
                        <div style={style.imageContainer}>
                            <img src={`${item.url}?`} style={style.image} alt={item.name} />
                            <Button
                                fill
                                text="Modifica"
                                intent="warning"
                                icon="edit"
                                style={{ borderRadius: 5, marginTop: 10 }}
                                onClick={async () => {
                                    var res = await fetch(`${settings.SERVER_URL}get-json/${item.id}`)
                                    var json = await res.json()
                                    store.loadJSON(json)
                                    dispatch(setSelectedTemplate(item))
                                    dispatch(setDrawerOpen(false))
                                }} />
                            <Button
                                fill
                                text="Elimina"
                                intent="danger"
                                icon="trash"
                                style={{ borderRadius: 5, marginTop: 10 }}
                                onClick={() => { setTemplate(item); setAlertOpen(true) }} />
                            <MenuDivider />
                            <div style={{ padding: "5px 7px", display: 'flex', alignItems: 'center' }}>
                                <Icon icon="edit" size={12} style={{ marginRight: 5 }} />
                                <EditableText
                                    fill
                                    minLines={1}
                                    maxLines={1}
                                    defaultValue={item.name}
                                    placeholder={'Nome lavoro'}
                                    icon="edit"
                                    onChange={(e) => setNewName(e)}
                                    onConfirm={() => editNameTemplate(item)}
                                    onEdit={(e) => setNewName(e)}
                                />

                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
})