import { setTranslations, getTranslations } from 'polotno/config';
setTranslations({
  sidePanel: {
    bodyText: "Body text",
    error: "Caricamento fallito...",
    noLayers: "Nessun elemento sulla pagina...",
    layers: "Livelli",
    createBody: "Create body text",
    createHeader: "Crea titolo",
    createSubHeader: "Crea sotto titolo",
    searchPlaceholder: "Cerca...",
    searchTemplatesWithSameSize: "Mostra template con la stessa dimensione",
    subHeaderText: "Sotto titolo",
    useMagicResize: 'Usa magic resize',
    width: 'Larghezza',
    background: "Sfondi",
    elements: "Elementi",
    magicResizeDescription: "Magic resize will automatically resize and move all elements on the canvas",
    myFonts: "I miei font",
    noResults: "Nessun risultato",
    otherFormats: "Altri formati",
    photos: "Foto",
    resize: "Dimensioni",
    templates: "Template",
    text: "Testi",
    upload: "Carica",
    uploadFont: "Carica font",
    uploadImage: "Carica immagine",
    uploadTip: "Vuoi caricare le tue immagini?"
  },
  toolbar: {
    opacity: 'Opacità',
    cropDone: 'Fatto',
    cropCancel: 'Chiudi',
    removeClip: 'Rimuovi clip',
    removeBackground: 'Rimuovi background',
    position: 'Posizione',
    effects: 'Effetti',
    border: 'Bordo',
    cornerRadius: 'Curvatura angolo',
    crop: 'Taglia',
    fitToBackground: 'Adatta allo sfondo',
    flip: 'Capovolgi',
    flipHorizontally: 'Capovolgi orizzontalmente',
    flipVertically: 'Capovolgi verticalmente',
    blur: "Sfocatura",
    brightness: "Luminosità",
    sepia: "Seppia",
    grayscale: "Scala di grigi",
    textStroke: "Tratto di testo",
    shadow: "Ombra",
    transparency: "Transparenza",
    unlockedDescription: "L'oggetto è sbloccato. Bloccalo per impedire modifiche.",
    lockedDescription: "L'oggetto è bloccato. Sbloccalo per consentire le modifiche",
    duplicateElements: "Duplica elementi",
    removeElements: "Rimuovi elementi",
    saveAsImage: "Salva come immagine",
    saveAsPDF: "Salva come PDF",
    alignBottom: "Allinea in basso",
    alignCenter: "Allinea al centro",
    alignLeft: "Allinea a sinistra",
    alignMiddle: "Allinea nel mezzo",
    alignRight: "Allinea a destra",
    alignTop: "Allinea in alto",
    alwaysOnTop: "Sempre in alto",
    toBottom: "In fondo",
    toForward: "In cima",
    up: "In alto",
    down: "In basso",
    layering: "Strati"
  },
  workspace: {
    duplicatePage: "Duplica pagina",
    removePage: "Rimuovi pagina",
    addPage: "Aggiungi pagina",
    moveUp: "Sposta su",
    moveDown: "Sposta giù",
    noPages: "Non ci sono ancora pagine..."

  }
});