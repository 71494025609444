export const COUNT = "COUNT";
export const INIT_LOADING_APP = "INIT_LOADING_APP";
export const END_LOADING_APP = "END_LOADING_APP";
export const INIT_PARAMS = "INIT_PARAMS";
export const SET_WORKNAME = "SET_WORKNAME";
export const SET_SHOW_MODAL = "SET_SHOW_MODAL";
export const GET_FORMAT_DETAIL = "GET_FORMAT_DETAIL";
export const GET_TEMPLATE_DETAIL = "GET_TEMPLATE_DETAIL";
export const POST_SAVE_TEMPLATE = "POST_SAVE_TEMPLATE";
export const GET_TEMPLATE_DETAIL_TO_UPDATE_PAGE =
  "GET_TEMPLATE_DETAIL_TO_UPDATE_PAGE";
export const GET_PAGE_DETAIL = "GET_PAGE_DETAIL";
export const GET_TEMPLATE_DETAIL_TO_ADD_PAGE =
  "GET_TEMPLATE_DETAIL_TO_ADD_PAGE";
export const SET_MULTIPAGE = "SET_MULTIPAGE";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const ERROR = "ERROR";
export const DO_API_CLOSE_WINDOW = "DO_API_CLOSE_WINDOW";
export const SET_PAGE_CONTROLS_ENABLED = "SET_PAGE_CONTROLS_ENABLED";
export const SET_SELECTED_FORMAT_AND_TYPE = "SET_SELECTED_FORMAT_AND_TYPE";
export const CLIENT_MODE = "CLIENT_MODE";
export const SET_PERMISSION = "SET_PERMISSION";
