import React from "react";
import { observer } from "mobx-react-lite";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import { nanoid } from "nanoid";
import { Button, Intent } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import {
  loadTemplates,
  setRefreshTemplates,
} from "../redux/clientReducer/action";
import { setShowModal } from "../redux/auth/action";
import getPermission from "../config/permission";
import { toast } from "react-toastify";
import settings from "../config/setting";
import Pagination from "react-bootstrap/Pagination";
import { CustomAlert } from "../components/CustomAlert";
import { setDrawerOpen } from "../redux/drawerReducer/action";
export const ClientWorksPanel = observer(({ store }) => {
  const {
    authReducer: {
      id_client,
      id_format,
      actionToDo,
      permissionPack,
      notShowModal,
    },
  } = useSelector((state) => state);
  const {
    clientReducer: {
      templates,
      loadingClientTemplates,
      refreshClientTemplates,
      pagination_t,
      pagesize_t,
      page_t,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [jsonToLoad, setJsonToLoad] = React.useState(false);
  const [focuspage, setFocuspage] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [term, setTerm] = React.useState("");
  const toastOption = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  React.useEffect(() => {
    dispatch(
      loadTemplates(
        id_client,
        id_format,
        pagesize_t,
        page_t,
        pagination_t,
        term
      )
    );
  }, []);

  const reload = () => {
    dispatch(setRefreshTemplates(true));
    setTimeout(() => {
      dispatch(
        loadTemplates(id_client, id_format, pagesize_t, 1, pagination_t, term)
      );
    }, 500);
  };

  const verify = async (json) => {
    if (
      store.pages.length + json.pages.length - 1 <=
      getPermission(permissionPack).max_publication_pages
    ) {
      json.pages.forEach((page) => {
        page.children.forEach((element) => {
          element.id = nanoid(10);
        });
      });
      var activePage = store.pages.indexOf(store.activePage);
      setJsonToLoad(json);
      setFocuspage(activePage);
      var currentJSON = store.toJSON();
      if (currentJSON.pages[activePage].children.length > 0) {
        notShowModal ? loadJson(json, activePage) : setIsOpen(true);
      } else {
        loadJson(json, activePage);
      }
    } else {
      toast.warn(
        "Hai raggiunto il numero massimo di pagine per questa pubblicazione!",
        toastOption
      );
    }
  };

  const loadJson = (json, active) => {
    var jsonLoad = json ? json : jsonToLoad;
    jsonLoad.pages.forEach((p) => (p.id = nanoid(10)));
    var pages = [];
    var currentJSON = store.toJSON();
    var activePage = store.pages.indexOf(store.activePage);
    currentJSON.pages.map((item, index) => {
      if (index == activePage) {
        jsonLoad.pages.map((item) => {
          pages.push(item);
        });
      } else {
        pages.push(item);
      }
    });
    if (actionToDo === "create-page" || actionToDo === "insert-page") {
      jsonLoad.pages = pages.length > 0 ? [pages[0]] : [];
    } else {
      jsonLoad.pages = pages;
    }
    store.loadJSON(jsonLoad);
    var index = active || active === 0 ? active : focuspage;
    store.selectPage(store.pages[index].id);
    setIsOpen(false);
  };

  const goTofirstPage = () => {
    dispatch(
      loadTemplates(id_client, id_format, pagesize_t, 1, pagination_t, term)
    );
  };

  const goTolastPage = () => {
    dispatch(
      loadTemplates(
        id_client,
        id_format,
        pagesize_t,
        parseInt(pagination_t.last),
        pagination_t,
        term
      )
    );
  };

  const nextPage = () => {
    if (pagination_t.active !== pagination_t.last) {
      dispatch(
        loadTemplates(
          id_client,
          id_format,
          pagesize_t,
          parseInt(pagination_t.active) + 1,
          pagination_t,
          term
        )
      );
    }
  };

  const prevPage = () => {
    if (pagination_t.active !== 1) {
      dispatch(
        loadTemplates(
          id_client,
          id_format,
          pagesize_t,
          parseInt(pagination_t.active) - 1,
          pagination_t,
          term
        )
      );
    }
  };

  const goToPage = (page) => {
    dispatch(
      loadTemplates(id_client, id_format, pagesize_t, page, pagination_t, term)
    );
  };

  const verifyNotShow = (e) => {
    dispatch(setShowModal(e.target.checked));
  };

  const openDrawerTemplates = () => {
    dispatch(setDrawerOpen(true, "works", "Gestisci i miei Lavori"));
    dispatch(
      loadTemplates(
        id_client,
        id_format,
        pagesize_t,
        page_t,
        pagination_t,
        term
      )
    );
  };

  const searchWorksByName = (e) => {
    setTerm(e.target.value);
    dispatch(
      loadTemplates(
        id_client,
        id_format,
        pagesize_t,
        page_t,
        pagination_t,
        e.target.value
      )
    );
  };

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CustomAlert
          store={store}
          message={`Attenzione! Cambiando il layout di questa pagina sovrascriverai la grafica corrente, continuare`}
          isOpen={modalIsOpen}
          confirmButtonText={"Continua"}
          cancelButtonText={"Chiudi"}
          icon={"warning-sign"}
          intent={Intent.WARNING}
          handleConfirm={() => loadJson()}
          handleCancel={() => setIsOpen(false)}
          checkbox={true}
          checkboxValue={notShowModal}
          checkboxText={"Non visualizzare più"}
          onChangeValueCheckbox={verifyNotShow}
        />
        <div className="bp4-input-group .modifier" style={{ marginBottom: 10 }}>
          <span className="bp4-icon bp4-icon-search"></span>
          <input
            type="text"
            className="bp4-input"
            placeholder="Ricerca per nome..."
            onChange={(e) => {
              searchWorksByName(e);
            }}
          />
        </div>
        <Button
          text={"Gestisci i miei lavori"}
          intent="primary"
          icon="cog"
          style={{ marginBottom: 10 }}
          onClick={() => {
            openDrawerTemplates();
          }}
        />
        <Button
          text={"Ricarica lavori"}
          intent="primary"
          icon="refresh"
          style={{ marginBottom: 10 }}
          loading={refreshClientTemplates}
          onClick={() => {
            reload(2);
          }}
        />
        {
          <ImagesGrid
            shadowEnabled={true}
            images={templates}
            getPreview={(image) => {
              if (image.thumb) {
                return `${image.thumb}?`;
              } else {
                return `${image.url}?`;
              }
            }}
            onSelect={async (image) => {
              var res = await fetch(
                `${settings.SERVER_URL}get-json/${image.id}`
              );
              var json = await res.json();
              verify(json);
              // dispatch(setSelectedTemplate(image.id))
            }}
            rowsNumber={2}
            isLoading={loadingClientTemplates}
          />
        }
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Pagination>
            <Pagination.First onClick={() => goTofirstPage()} />
            <Pagination.Prev onClick={() => prevPage()} />
            {parseInt(pagination_t.active) === 1 ? (
              <Pagination.Ellipsis />
            ) : (
              <Pagination.Item
                onClick={() => goToPage(parseInt(pagination_t.active) - 1)}
              >
                {parseInt(pagination_t.active) - 1}
              </Pagination.Item>
            )}
            <Pagination.Item active>
              {parseInt(pagination_t.active)}
            </Pagination.Item>
            {parseInt(pagination_t.active) === parseInt(pagination_t.last) ? (
              <Pagination.Ellipsis />
            ) : (
              <Pagination.Item
                onClick={() => goToPage(parseInt(pagination_t.active) + 1)}
              >
                {parseInt(pagination_t.active) + 1}
              </Pagination.Item>
            )}
            <Pagination.Next onClick={() => nextPage()} />
            <Pagination.Last onClick={() => goTolastPage()} />
          </Pagination>
        </div>
      </div>
    </>
  );
});
