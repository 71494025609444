import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  ButtonGroup,
  Divider,
  Menu,
  MenuItem,
  MenuDivider,
} from "@blueprintjs/core";
import { Tooltip2, Popover2 } from "@blueprintjs/popover2";
import getPermission from "../config/permission";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setMultipage, setSelectedFormatAndType } from "../redux/auth/action";
import {
  getDefaultTemplates,
  setLoadingInterattivoTemplates,
} from "../redux/interattivoReducer/action";
import { nanoid } from "nanoid";

export const PageControls = observer(
  ({ store, page, xPadding, yPadding, user }) => {
    const toastOption = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      limit: 1,
    };
    const dispatch = useDispatch();
    const {
      authReducer: {
        permissionPack,
        formatName,
        actionToDo,
        id_format,
        demo,
        workname,
        permission,
      },
    } = useSelector((state) => state);
    const {
      formatReducer: { formats, types },
    } = useSelector((state) => state);
    const {
      interattivoReducer: { pagination, pagesize },
    } = useSelector((state) => state);
    const max_num_pages = getPermission(permissionPack);
    const meta = page.custom?.meta;
    const activePage = store.pages.indexOf(store.activePage);
    const hasManyPages = true;
    const canAdd =
      store.pages.length < max_num_pages.max_publication_pages &&
      permission.canAddPage;
    const canDuplicate =
      store.pages.length < max_num_pages.max_publication_pages &&
      permission.canDuplicatePage;
    const canRemove = store.pages.length > 1;
    const canClean =
      store.toJSON().pages[activePage].children.length > 0 ||
      store.toJSON().pages[activePage].background !== "white";
    const canChangeFormat = demo || actionToDo === "client-mode";
    const index = store.pages.indexOf(store.activePage);
    const checkPage = () => {
      if (store.pages.length === max_num_pages.max_publication_pages) {
        toast.warn(
          "Hai raggiunto il numero massimo di pagine per questa pubblicazione!",
          toastOption
        );
      }
    };
    // const name = (meta?.name && (meta?.name[0].name || meta?.name[0].value)) || '';
    // const pagesWithSameType = store.pages.filter(
    //     (p) => p.custom?.meta?.type === page.custom?.meta?.type
    // );

    const rendermenu = () => {
      return (
        <Menu>
          {types.map((item, index) => {
            return (
              <MenuItem
                key={`${nanoid(10)}`}
                text={item.name}
                onClick={() => console.log()}
                rightIcon="caret-down"
                children={renderChildren(item.formats)}
              />
            );
          })}
        </Menu>
      );
    };

    const renderChildren = (formats) => {
      return formats.map((item) => {
        return (
          <MenuItem
            key={`${nanoid(10)}`}
            text={`${item.name} (${item.width} x ${item.height})`}
            icon={item.id === id_format ? "selection" : "circle"}
            onClick={() => resizePage(item)}
          />
        );
      });
    };

    const resizePage = (item) => {
      dispatch(setLoadingInterattivoTemplates(true));
      dispatch(getDefaultTemplates(item.id, pagesize, 1, pagination, ""));
      dispatch(setSelectedFormatAndType(item));
      store.setSize(parseFloat(item.width), parseFloat(item.height), true);
      dispatch(setMultipage(item.is_multipage));
    };

    return (
      <>
        <ButtonGroup
          minimal={true}
          vertical={false}
          style={{
            position: "absolute",
            top: yPadding - 40 + "px",
            left: xPadding + "px",
            color: "darkgray",
          }}
        >
          <Button text={`${1 + +index}/${store.pages.length}`} disabled />
          <Divider />
          <Popover2
            popoverClassName={""}
            portalClassName="foo"
            content={rendermenu()}
            enforceFocus={undefined}
            disabled={!canChangeFormat}
            placement={"bottom-start"}
          >
            <Button
              text={formatName}
              rightIcon="caret-down"
              disabled={!canChangeFormat}
            />
          </Popover2>
          {/* <Divider />
                <Button text={workname} disabled /> */}
        </ButtonGroup>
        <div
          style={{
            position: "absolute",
            top: yPadding - 40 + "px",
            right: xPadding + "px",
          }}
        >
          {hasManyPages && (
            <Tooltip2 content="Sposta in alto" disabled={activePage === 0}>
              <Button
                icon="chevron-up"
                minimal
                disabled={index === 0}
                onClick={() => {
                  page.setZIndex(index - 1);
                }}
              ></Button>
            </Tooltip2>
          )}
          {hasManyPages && (
            <Tooltip2
              content="Sposta in basso"
              disabled={activePage === store.pages.length - 1}
            >
              <Button
                icon="chevron-down"
                minimal
                disabled={index === store.pages.length - 1}
                onClick={() => {
                  const index = store.pages.indexOf(page);
                  page.setZIndex(index + 1);
                }}
              ></Button>
            </Tooltip2>
          )}
          {
            <Tooltip2 content={"Duplica"} disabled={!canDuplicate}>
              <Button
                disabled={!canDuplicate}
                icon="duplicate"
                minimal
                onClick={() => {
                  page.clone();
                  checkPage();
                }}
              ></Button>
            </Tooltip2>
          }
          {
            <Tooltip2 content="Rimuovi pagina" disabled={!canRemove}>
              <Button
                icon="trash"
                minimal
                disabled={!canRemove}
                onClick={() => {
                  store.deletePages([page.id]);
                }}
              ></Button>
            </Tooltip2>
          }
          {
            <Tooltip2 content="Aggiungi pagina" disabled={!canAdd}>
              <Button
                icon="insert"
                minimal
                disabled={!canAdd}
                onClick={() => {
                  const newPage = store.addPage({
                    bleed: store.activePage?.bleed || 0,
                    custom: { meta },
                    width: page.width,
                    height: page.height,
                  });
                  const index = store.pages.indexOf(page);
                  newPage.setZIndex(index + 1);
                  checkPage();
                }}
              ></Button>
            </Tooltip2>
          }
          {
            <Tooltip2 content="Pulisci pagina" disabled={!canClean}>
              <Button
                icon="clean"
                minimal
                disabled={!canClean}
                onClick={() => {
                  store.activePage.set({ background: "white" });
                  store.toJSON().pages[activePage].children.map((children) => {
                    store.deleteElements([children.id]);
                  });
                }}
              ></Button>
            </Tooltip2>
          }
        </div>
      </>
    );
  }
);
