
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Pre, Spinner, Elevation, Tag, H6, Intent } from "@blueprintjs/core";
import { getFormat, getTemplateTypeList, setLoadingFormat } from '../redux/formatReducer/action'
import { setMultipage, setSelectedFormatAndType } from '../redux/auth/action';
export const FormatPanel = observer(({ store }) => {
  const { formatReducer: { formats, types, loadingFormat, loadingTypes } } = useSelector(state => state)
  const { authReducer: { id_format } } = useSelector(state => state)
  const [openId, setOpenId] = React.useState(false)
  const dispatch = useDispatch()
  
  React.useEffect(() => {
    dispatch(getTemplateTypeList())
  }, [])

  const resizePage = (item) => {
    dispatch(setSelectedFormatAndType(item.id, item.templateType))
    store.setSize(parseFloat(item.width), parseFloat(item.height), true)
    dispatch(setMultipage(item.is_multipage))
  }

  const _handleClick = (id_item) => {
    if (openId === id_item) {
      setOpenId(false)
    } else {
      dispatch(setLoadingFormat(true))
      dispatch(getFormat(id_item))
      setOpenId(id_item)
    }
  }

  const renderFormats = () => {
    if (!loadingFormat) {
      if (formats.length > 0) {
        return (formats.map((item, index) => {
          return (
            <Tag
              rightIcon={ item.id === id_format ? 'selection':'circle'}
              onClick={() => resizePage(item)}
              active={item.id === id_format}
              interactive={true}
              fill
              large
              elevation={Elevation.TWO}
              minimal
              style={{ marginBottom: 10, alignItems: 'center', justifyContent: 'center' }}>
              {`${item.name} (${item.width} x ${item.height})`}
            </Tag>
          )
        }))
      } else {
        return (
          <Pre>
            Nessun formato disponibile
          </Pre>
        )
      }
    }
  }

  return (
    loadingTypes ? <Spinner /> :
      <div>
        <H6>
          {'Formati:'}
        </H6>
        {types.map((item, index) => {
          return (
            <>
              <Tag
                loading={openId === item.id && loadingFormat}
                large
                onClick={() => _handleClick(item.id)}
                icon={openId === item.id ? 'caret-down' : 'caret-right'}
                fill
                alignText='left'
                style={{ marginBottom: 10 }}
                intent={Intent.PRIMARY}
                minimal
              >
                {item.name}
              </Tag>
              <Collapse isOpen={item.id === openId}>
                {renderFormats()}
              </Collapse>
            </>
          )
        })}
      </div>

  );
});