import authReducer from "./auth/reducer";
import drawerReducer from "./drawerReducer/reducer";
import interattivoReducer from "./interattivoReducer/reducer";
import prodottiReducer from "./prodottiReducer/reducer";
import clientReducer from "./clientReducer/reducer";
import saveReducer from "./saveReducer/reducer";
import elementReducer from "./elementReducer/reducer";
import formatReducer from "./formatReducer/reducer";
export default {
    authReducer,
    drawerReducer,
    interattivoReducer,
    prodottiReducer,
    clientReducer,
    saveReducer,
    elementReducer,
    formatReducer
};