import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import { getDefaultTemplates } from "../redux/interattivoReducer/action";
import { toast } from "react-toastify";
import getPermission from "../config/permission";
import settings from "../config/setting";
import { CustomAlert } from "../components/CustomAlert";
import { Intent } from "@blueprintjs/core";
import { setSelectedTemplate, setShowModal } from "../redux/auth/action";

export const InterattivoPanel = observer(({ store }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [jsonToLoad, setJsonToLoad] = useState(false);
  const [focuspage, setFocuspage] = useState(false);
  const [term, setTerm] = useState("");
  const [rowsNumber, setRowsNumber] = useState(2);
  const [isLoadingMore, setLoadingMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const isFetchingRef = useRef(false);
  const {
    authReducer: { id_format, permissionPack, notShowModal, actionToDo, demo },
  } = useSelector((state) => state);

  const {
    interattivoReducer: {
      loadingTemplates,
      templates,
      pagination,
      pagesize,
      page,
      hasMore,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const toastOption = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  Modal.setAppElement("#modal-close-tab");

  useEffect(() => {
    if (
      currentPage <= pagination.last &&
      isLoadingMore &&
      !isFetchingRef.current
    ) {
      isFetchingRef.current = true; 
      dispatch(
        getDefaultTemplates(id_format, pagesize, currentPage, pagination, term)
      ).finally(() => {
        isFetchingRef.current = false; 
        setLoadingMore(false); 
      });
    }
  }, [
    isLoadingMore,
    currentPage,
    dispatch,
    id_format,
    pagesize,
    pagination,
    term,
  ]);

  useEffect(() => {
    if (templates && templates.length > 0) {
      setRowsNumber(
        templates[0]?.format_page.width >= templates[0]?.format_page.height
          ? 1
          : 2
      );
    }
  }, [templates]);

  function closeModal() {
    setIsOpen(false);
  }

  const verify = async (json) => {
    if (
      store.pages.length + json.pages.length - 1 <=
      getPermission(permissionPack).max_publication_pages
    ) {
      json.pages.forEach((page) => {
        page.children.forEach((element) => {
          element.id = nanoid(10);
        });
      });
      var activePage = store.pages.indexOf(store.activePage);
      setJsonToLoad(json);
      setFocuspage(activePage);
      var currentJSON = store.toJSON();
      if (currentJSON.pages[activePage].children.length > 0) {
        notShowModal ? loadJson(json, activePage) : setIsOpen(true);
      } else {
        loadJson(json, activePage);
      }
    } else {
      toast.warn(
        "Hai raggiunto il numero massimo di pagine per questa pubblicazione!",
        toastOption
      );
    }
  };

  const loadJson = (json, active) => {
    var jsonLoad = json ? json : jsonToLoad;
    jsonLoad.pages.forEach((p) => (p.id = nanoid(10)));
    var x = [];
    var currentJSON = store.toJSON();
    var activePage = store.pages.indexOf(store.activePage);
    currentJSON.pages.map((item, index) => {
      if (index === activePage) {
        jsonLoad.pages.map((item) => x.push(item));
      } else {
        x.push(item);
      }
    });
    jsonLoad.pages = x;
    store.loadJSON(jsonLoad);
    var index = active || active === 0 ? active : focuspage;
    store.selectPage(store.pages[index].id);
    setIsOpen(false);
  };

  const searchDefaultTemplates = (text) => {
    setTerm(text.target.value);
    dispatch(
      getDefaultTemplates(id_format, pagesize, 1, pagination, text.target.value)
    );
  };

  const verifyNotShow = (e) => {
    dispatch(setShowModal(e.target.checked));
  };

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CustomAlert
          store={store}
          message={`Attenzione! Cambiando il layout di questa pagina sovrascriverai la grafica corrente, continuare`}
          isOpen={modalIsOpen}
          confirmButtonText={"Continua"}
          cancelButtonText={"Chiudi"}
          icon={"warning-sign"}
          intent={Intent.WARNING}
          handleConfirm={() => loadJson()}
          handleCancel={closeModal}
          checkbox={true}
          checkboxValue={notShowModal}
          checkboxText={"Non visualizzare più"}
          onChangeValueCheckbox={verifyNotShow}
        />
        <div className="bp4-input-group .modifier" style={{ marginBottom: 10 }}>
          <span className="bp4-icon bp4-icon-search"></span>
          <input
            type="text"
            className="bp4-input"
            placeholder="Ricerca per nome..."
            onChange={(e) => {
              searchDefaultTemplates(e);
            }}
          />
        </div>
        <ImagesGrid
          images={templates.map((template) => ({
            ...template,
            key: template.id + nanoid(), // Assicurati che ogni chiave sia unica
          }))}
          getPreview={(image) =>
            image.url ? `${image.url}?` : image.json_format
          }
          onSelect={async (image) => {
            var res = await fetch(`${settings.SERVER_URL}get-json/${image.id}`);
            var json = await res.json();
            verify(json);
          }}
          rowsNumber={rowsNumber}
          loadMore={async () => {
            if (hasMore && !isLoadingMore && currentPage < pagination.last) {
              setLoadingMore(true);
              setCurrentPage((currentPage) => currentPage + 1);
            }
          }}
        />
        {isLoadingMore && <h5 style={{ textAlign:"center"}}>Caricamento templates...</h5>}
      </div>
    </>
  );
});
