import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingApp,
  postSaveTemplate,
  setDoApiCloseWindow,
} from "../redux/auth/action";
import {
  Button,
  H5,
  Intent,
  InputGroup,
  ButtonGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Classes as CoreClasses,
} from "@blueprintjs/core";
import { toast } from "react-toastify";
import settings from "../config/setting";
import { save } from "../redux/saveReducer/action";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { setWorkname } from "../redux/auth/action";
export const SaveComponent = observer(({ store }) => {
  const toastOption = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const dispatch = useDispatch();
  const {
    authReducer: {
      workname,
      loadingApp,
      tokenDefinitivo,
      actionToDo,
      id_client,
      is_multipage,
      id_format,
      tab_id,
      id_template,
      id_template_type,
      pageIndex,
      demo,
      doApiCloseWindow,
      auth_api_url,
      redirect_url,
      has_works,
      tag_flat_list,
      update_banner_image,
    },
  } = useSelector((state) => state);
  const [popoverOpen] = React.useState(false);
  const [name, setName] = React.useState(workname);
  const canDownload = actionToDo === "client-mode" || demo;
  const canSaveAndApply = !demo && actionToDo !== "client-mode";
  const canSaveModelAndWorks = !demo;
  const canUpdateJson = demo || actionToDo === "client-mode";

  const replaceUndefinedWithRandom = (inputString) => {
    if (!inputString) return "_";
    const random_string = (Math.random() + 1).toString(36).substring(5);
    const replacedString = inputString.replace("undefined", random_string);
    return replacedString;
  };

  const saveTemplate = async (close, is_model) => {
    sessionStorage.setItem("shouldPromptBeforeUnload", JSON.stringify(false));
    dispatch(setWorkname(name));
    var json = store.toJSON();
    var isEmpty = true;
    var res = null;
    var loadedJson = null;
    var base64 = null;
    var newPageBase64 = null;
    var newPageJson = null;
    var newPdfJson = null;
    var url = null;
    var firstPage = null;

    json.pages.map((item) => {
      if (item.children.length > 0) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      toast.warn(
        "Il lavoro risulta vuoto, crea il tuo template per poterlo utilizzare su InPublish!",
        toastOption
      );
      dispatch(setLoadingApp(false));
      return;
    }
    dispatch(setLoadingApp(true));
    if (name === "") {
      toast.warn("Nome lavoro vuoto!", toastOption);
      dispatch(setLoadingApp(false));
      return;
    }

    if (id_template) {
      res = await fetch(`${settings.SERVER_URL}get-json/${id_template}`);
      loadedJson = await res.json();
    }
    var data = new FormData();
    id_client && data.append("id_client", id_client);

    data.append("is_model", is_model);
    data.append("json_format", JSON.stringify(json));
    data.append("name", has_works ? name : replaceUndefinedWithRandom(name));
    id_format && data.append("id_format_page", id_format);
    !id_format && data.append("page_width", parseFloat(store.width).toFixed(1));
    !id_format &&
      data.append("page_height", parseFloat(store.height).toFixed(1));
    id_template && data.append("id_template", id_template);
    id_template_type && data.append("id_template_type", id_template_type);

    await store.waitLoading();
    if (is_model) {
      url = settings.SERVER_URL + "create-client-template/";
    } else {
      if (actionToDo === "new" || actionToDo === "client-mode") {
        if (tag_flat_list && tag_flat_list !== "") {
          data.append("tags", tag_flat_list.toLowerCase());
        }

        url = settings.SERVER_URL + "create-client-template/";
      } else if (actionToDo === "upd") {
        data.append("update_banner_image", update_banner_image);
        url = settings.SERVER_URL + "update-client-template/";
      } else if (actionToDo === "update-page") {
        url = settings.SERVER_URL + "update-page-from-pdf/";
        newPageBase64 = await store.toDataURL();
        newPageJson = json.pages[0];
        newPdfJson = loadedJson;
        newPdfJson.pages[parseInt(pageIndex) - 1] = newPageJson;
        data.append("first_page", firstPage);
        data.append("new_page_base64", newPageBase64);
        data.append("new_pdf_json", JSON.stringify(newPdfJson));
      } else if (actionToDo === "create-page") {
        url = settings.SERVER_URL + "create-new-page-for-pdf/";
        newPageBase64 = await store.toDataURL();
        newPageJson = json.pages[0];
        newPdfJson = loadedJson;
        if (pageIndex === 0) {
          var temp_pages_array = newPdfJson.pages;
          temp_pages_array.push(newPageJson);
          newPdfJson.pages = temp_pages_array;
        } else {
          newPdfJson.pages.splice(parseInt(pageIndex) - 1, 0, newPageJson);
        }
        data.append("first_page", firstPage);
        data.append("new_page_base64", newPageBase64);
        data.append("new_page_json", JSON.stringify(newPageJson));
        data.append("new_pdf_json", JSON.stringify(newPdfJson));
      } else if (actionToDo === "insert-page") {
        url = settings.SERVER_URL + "create-client-template/";
      }
    }

    if (is_multipage) {
      await store.waitLoading();
      firstPage = await store.toDataURL({ pageId: store.pages[0].id });
      data.append("first_page", firstPage);
      await store.waitLoading();
      base64 = await store.toPDFDataURL();
      data.append("pdf_format", base64);
    } else {
      await store.waitLoading();
      base64 = await store.toDataURL();
      data.append("image_base64", base64);
    }
    /* 
            Controllo dimensione del PDF
        */
    var stringLength = base64.length - "data:image/png;base64,".length;
    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInKb = sizeInBytes / 1000;
    var sizeInMB = sizeInKb / 1000;
    if (parseFloat(sizeInMB) > parseFloat(settings.MAX_UPLOAD_FILE_SIZE)) {
      toast.error(
        "Dimensione file troppo grande, non deve superare i " +
          settings.MAX_UPLOAD_FILE_SIZE +
          " MB",
        toastOption
      );
      dispatch(setLoadingApp(false));
      return;
    }
    /* 
            Fine controllo
        */
    dispatch(
      save(
        url,
        data
      )((result) => {
        if (result.success) {
          if (close) {
            closeWindow(result.data, false);
          } else {
            if (is_model) {
              dispatch(setLoadingApp(false));
              toast.success("Lavoro salvato correttamente!", toastOption);
            } else {
              dispatch(postSaveTemplate(result));
              dispatch(setLoadingApp(false));
              toast.success("Lavoro salvato correttamente!", toastOption);
            }
          }
        }
      })
    );
  };

  const closeWindow = (template, undo) => {
    sessionStorage.setItem("shouldPromptBeforeUnload", JSON.stringify(false));

    dispatch(setLoadingApp(true));
    dispatch(setDoApiCloseWindow(false));
    var data = new FormData();
    data.append("undo", undo);
    data.append("client_id", id_client);
    data.append("token", tokenDefinitivo);
    data.append("polotno_id", template ? template.id : null);
    if (template)
      data.append("url", is_multipage ? template.pdf_format : template.url);
    fetch(auth_api_url, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setTimeout(() => {
            if (redirect_url)
              window.location.assign(
                redirect_url +
                  "?client_id=" +
                  id_client +
                  "&token=" +
                  tokenDefinitivo +
                  "&tab_id=" +
                  tab_id
              );
            else
              window.location.assign(
                settings.INTERATTIVO_URL +
                  "?client_id=" +
                  id_client +
                  "&token=" +
                  tokenDefinitivo +
                  "&tab_id=" +
                  tab_id
              );
          }, 1000);
        } else {
          dispatch(setLoadingApp(false));
          toast.error("errore del server");
        }
      })
      .catch((error) => {
        console.log("api/polotno", error);
        toast.error(error);
        dispatch(setLoadingApp(false));
        dispatch(setDoApiCloseWindow(true));
      });
  };

  const downloadJSON = () => {
    const json = store.toJSON();
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(json)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `inGraph-${Date.now()}.json`;

    link.click();
  };

  const downloadPDF = () => {
    store.saveAsPDF({ fileName: `inGraph-${Date.now()}.pdf` });
  };

  const downloadPNG = () => {
    store.saveAsImage({ fileName: `inGraph-${Date.now()}.png` });
  };

  const updateJSON = async () => {
    if (!id_template) {
      toast.warning("Aggiungere template al foglio di lavoro!", toastOption);
      return;
    }
    var firstPage = await store.toDataURL({ pageId: store.pages[0].id });
    var url = settings.SERVER_URL + "update-json-template/";
    var data = new FormData();
    data.append("id_template", id_template);
    firstPage && data.append("first_page", firstPage);
    data.append("json_format", JSON.stringify(store.toJSON()));
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(setLoadingApp(false));
        toast.success(response.message, toastOption);
      })
      .catch((error) => {
        console.log("auth?token => ERROR", error);
      });
  };

  const _handleInputChange = (e) => {
    setName(e.target.value);
  };

  const rendermenu = () => {
    return (
      <div style={{ padding: 10 }}>
        <div key="input">
          <label className={CoreClasses.LABEL}>
            Nome lavoro
            <InputGroup
              asyncControl={true}
              disabled={false}
              medium={true}
              leftIcon="draw"
              onChange={_handleInputChange}
              placeholder="Nome lavoro..."
              value={name}
            />
          </label>
        </div>
        <Menu>
          {
            <MenuItem
              text="Download JSON"
              icon="download"
              disabled={!canDownload}
              onClick={() => downloadJSON()}
            />
          }
          {
            <MenuItem
              text="Download PNG"
              icon="media"
              disabled={!canDownload}
              onClick={() => downloadPNG()}
            />
          }
          {
            <MenuItem
              text="Download PDF"
              icon="manual"
              disabled={!canDownload}
              onClick={() => downloadPDF()}
            />
          }
          <MenuDivider />
          {
            <MenuItem
              text="Aggiorna JSON"
              icon="edit"
              disabled={!canUpdateJson}
              onClick={() => {
                dispatch(setLoadingApp(true));
                updateJSON();
              }}
              intent="warning"
            />
          }
          {
            <MenuItem
              text="Salva come Modello"
              icon="add-to-folder"
              disabled={!canSaveModelAndWorks}
              intent="primary"
              onClick={
                !loadingApp
                  ? () => {
                      saveTemplate(false, true);
                    }
                  : null
              }
            />
          }
          {
            <MenuItem
              text="Salva come Lavoro"
              icon="projects"
              disabled={!canSaveModelAndWorks}
              intent="primary"
              onClick={
                !loadingApp
                  ? () => {
                      saveTemplate(false, false);
                    }
                  : null
              }
            />
          }
          <MenuDivider />
          <MenuItem
            text="Salva e applica"
            icon="floppy-disk"
            disabled={!canSaveAndApply}
            intent="success"
            onClick={
              !loadingApp
                ? () => {
                    saveTemplate(true, false);
                  }
                : null
            }
          />
        </Menu>
      </div>
    );
  };
  return (
    <>
      {!has_works && (
        <>
          <Button
            text="Salva"
            icon="floppy-disk"
            onClick={() => {
              if (!loadingApp) saveTemplate(true, false);
            }}
            style={{ width: "45%", marginRight: 10 }}
            intent="success"
            className={Classes.POPOVER2_DISMISS}
            // loading={loadingApp}
          />
          {canSaveModelAndWorks && (
            <Button
              text="Salva come modello"
              icon="add-to-folder"
              onClick={() => {
                if (!loadingApp) saveTemplate(false, true);
              }}
              style={{ width: "90%", marginRight: 10 }}
              intent="primary"
            />
          )}
        </>
      )}
      <div style={{ display: "flex" }}>
        {has_works && (
          <ButtonGroup
            style={{ width: "100%", marginRight: 10 }}
            intent="success"
            vertical
          >
            <Popover2
              isOpen={popoverOpen ? popoverOpen : undefined}
              popoverClassName={""}
              portalClassName="foo"
              content={rendermenu()}
              enforceFocus={undefined}
              placement={"bottom-start"}
            >
              <Button
                rightIcon={"caret-down"}
                text="Salva"
                icon="floppy-disk"
                style={{ width: "100%", marginRight: 10 }}
                intent="success"
                loading={loadingApp}
              />
            </Popover2>
          </ButtonGroup>
        )}
        <Popover2
          interactionKind="click"
          popoverClassName={Classes.POPOVER2_DISMISS}
          placement="bottom"
          content={
            <div key="text" style={{ padding: 20 }}>
              <H5>Confermi di voler chiudere?</H5>
              <p>
                Sei sicuro di voler continuare? Tutte le modifiche non salvate
                andranno perse.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 15,
                }}
              >
                <Button
                  className={Classes.POPOVER2_DISMISS}
                  style={{ marginRight: 10 }}
                >
                  Cancella
                </Button>
                <Button
                  onClick={() => {
                    closeWindow(null, true);
                  }}
                  intent={Intent.DANGER}
                  className={Classes.POPOVER2_DISMISS}
                >
                  Chiudi
                </Button>
              </div>
            </div>
          }
          renderTarget={({ isOpen, ref, ...targetProps }) => (
            <Button
              {...targetProps}
              elementRef={ref}
              text="Chiudi"
              icon="cross"
              style={{ width: "100%" }}
              intent="danger"
              fill
            />
          )}
        />
      </div>
    </>
  );
});
