import {
    INIT_LOADING_DRAWER,
    END_LOADING_DRAWER,
    SET_DRAWER_OPEN,
    ERROR
} from './type'
const initState = {
    loadingDrawer: false,
    drawerOpen: false,
    type: false,
    title: ''

}
export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case INIT_LOADING_DRAWER:
            return {
                ...state,
                loadingDrawer: true
            }
        case END_LOADING_DRAWER:
            return {
                ...state,
                loadingDrawer: false
            }
        case SET_DRAWER_OPEN:
            return {
                ...state,
                drawerOpen: action.payload.value,
                type: action.payload.type,
                title: action.payload.title
            }
        case ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}