import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
const middlewares = [thunk];
const composeEnhancer =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
const store = createStore(
  combineReducers({
    ...reducers,
  }),
  composeEnhancer(applyMiddleware(...middlewares))
);
store.subscribe(() => {
  // localStorage.setItem('accessToken', store.getState().auth.accessToken)
  // localStorage.setItem('isLogin', store.getState().auth.isLogin)
});
export default store;