const getPermission = permission => {
    if (parseInt(permission) > 0) {
        switch (permission) {
            case 1:
                return {
                    // FREE
                    id: 1,
                    max_publication_pages: 4,
                    max_publication_dimension_pdf: 30,
                }
            case 2:
                // ESSENTIAL
                return {
                    id: 2,
                    max_publication_pages: 100,
                    max_publication_dimension_pdf: 30,
                }
            case 3:
                // STANDARD
                return {
                    id: 3,
                    max_publication_pages: 100,
                    max_publication_dimension_pdf: 30,
                }
            case 4:
                //PREMIUM
                return {
                    id: 4,
                    max_publication_pages: 100,
                    max_publication_dimension_pdf: 30,
                }
            case 5:
                // ENTERPRISE
                return {
                    id: 5,
                    max_publication_pages: 10000000,
                    max_publication_dimension_pdf: 10000000,
                }
            default:
                return {
                    id: 5,
                    max_publication_pages: 4,
                    max_publication_dimension_pdf: 30,
                }
        }
    } else {
        return {
            id: -1,
            max_publication_pages: 30,
            max_publication_dimension_pdf: 30,
        }
    }

}

export default getPermission;