import { Classes, Drawer } from "@blueprintjs/core";
import { setDrawerOpen } from '../redux/drawerReducer/action';
import { observer } from 'mobx-react-lite';
import { useDispatch, useSelector } from 'react-redux';
import { CustomImagesGrid } from "./CustomImagesGrid";
export const MyDrawer = observer(({ store }) => {
    const { drawerReducer: { drawerOpen, title, type } } = useSelector(state => state)
    const { clientReducer: { templates, models } } = useSelector(state => state)
    const dispatch = useDispatch()

    const renderEmpty = () => {
        if (type === 'models' && models.length === 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', margin: 30, fontSize: 14, fontWeight: '400' }}>
                    Nessun risultato
                </div>
            )
        } else {
            if (templates.length === 0) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', margin: 30, fontSize: 14, fontWeight: '400' }}>
                        Nessun risultato
                    </div>
                )
            }
        }
    }

    return (
        <Drawer
            isOpen={drawerOpen}
            size={'50%'}
            icon="info-sign"
            onClose={() => { dispatch(setDrawerOpen(false)) }}
            title={title}
        >
            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>
                    <CustomImagesGrid
                        store={store}
                        data={type === 'models' ? models : templates}
                        type={type}
                    />
                    {renderEmpty()}
                </div>
            </div>
        </Drawer >
    )
})