import { observer } from 'mobx-react-lite';
export const AppVersion = observer(({ store }) => {

    var pjson = require('../../package.json');

    return (
        <span style={{
          flex: 1,
          position: 'absolute',
          bottom: 20,
          right: 20,
          color: '#9DA9B3',
          fontWeight: '500'
        }}>
          {'InGraph v' + pjson.version}
        </span>
      )
})