import React from "react";
import { TemplatesSection } from "polotno/side-panel";
import { observer } from "mobx-react-lite";
import { templateList } from "polotno/utils/api";
import { useInfiniteAPI } from "polotno/utils/use-api";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../redux/auth/action";
import { toast } from "react-toastify";
import getPermission from "../config/permission";
import { CustomAlert } from "../components/CustomAlert";
import { Intent } from "@blueprintjs/core";

export const PolotnoTemplate = {
  name: TemplatesSection.name,
  Tab: TemplatesSection.Tab,
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [jsonToLoad, setJsonToLoad] = React.useState(false);
    const [focuspage, setFocuspage] = React.useState(false);

    const {
      authReducer: { notShowModal, permissionPack },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const toastOption = {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    const { setQuery, loadMore, hasMore, data, isLoading } = useInfiniteAPI({
      getAPI: ({ page, query }) =>
        templateList({
          page,
          query,
          sizeQuery: `size=${store.width}x${store.height}`,
        }),
      getSize: (res) => res.totalPages,
    });

    const verify = async (json) => {
      // console.log((store.pages.length + json.pages.length - 1), getPermission(permissionPack).max_publication_pages)
      if (
        store.pages.length + json.pages.length - 1 <=
        getPermission(permissionPack).max_publication_pages
      ) {
        var currentJSON = store.toJSON();
        var activePage = store.pages.indexOf(store.activePage);
        setJsonToLoad(json);
        setFocuspage(activePage);
        if (currentJSON.pages[activePage].children.length > 0) {
          notShowModal ? loadJson(json, activePage) : setIsOpen(true);
        } else {
          loadJson(json, activePage);
        }
      } else {
        toast.warn(
          "Hai raggiunto il numero massimo di pagine per questa pubblicazione!",
          toastOption
        );
      }
    };

    const loadJson = (json, active) => {
      var jsonLoad = json ? json : jsonToLoad;
      var x = [];
      var currentJSON = store.toJSON();
      var activePage = store.pages.indexOf(store.activePage);
      currentJSON.pages.map((item, index) => {
        if (index === activePage) {
          jsonLoad.pages.map((item) => {
            x.push(item);
          });
        } else {
          x.push(item);
        }
      });
      jsonLoad.pages = x;
      store.loadJSON(jsonLoad);
      var index = active || active === 0 ? active : focuspage;
      store.selectPage(store.pages[index].id);
      setIsOpen(false);
    };

    const verifyNotShow = (e) => {
      dispatch(setShowModal(e.target.checked));
    };

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CustomAlert
          store={store}
          message={`Attenzione! Cambiando il layout di questa pagina sovrascriverai la grafica corrente, continuare`}
          isOpen={modalIsOpen}
          confirmButtonText={"Continua"}
          cancelButtonText={"Chiudi"}
          icon={"warning-sign"}
          intent={Intent.WARNING}
          handleConfirm={() => loadJson()}
          handleCancel={() => setIsOpen(false)}
          checkbox={true}
          checkboxValue={notShowModal}
          checkboxText={"Non visualizzare più"}
          onChangeValueCheckbox={verifyNotShow}
        />
        {/* {<InputGroupBootstrap className="mb-3">
            <InputGroupBootstrap.Text id="basic-addon1"><BsSearch /></InputGroupBootstrap.Text>
            <FormControl
              placeholder="Ricerca per nome"
              aria-describedby="basic-addon1"
              onChange={(e) => setQuery(e.target.value)}
            />
          </InputGroupBootstrap>} */}
        <ImagesGrid
          shadowEnabled={true}
          images={data?.map((item) => item.items).flat()}
          getPreview={(image) => image.preview}
          isLoading={isLoading}
          onSelect={async (item) => {
            const req = await fetch(item.json);
            const json = await req.json();
            //store.loadJSON(json, true);
            verify(json);
          }}
          loadMore={hasMore && loadMore}
        />
      </div>
    );
  }),
};
