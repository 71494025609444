import {
  INIT_LOADING_APP,
  END_LOADING_APP,
  INIT_PARAMS,
  ERROR,
  SET_WORKNAME,
  SET_SHOW_MODAL,
  GET_FORMAT_DETAIL,
  GET_TEMPLATE_DETAIL,
  POST_SAVE_TEMPLATE,
  GET_TEMPLATE_DETAIL_TO_UPDATE_PAGE,
  GET_TEMPLATE_DETAIL_TO_ADD_PAGE,
  GET_PAGE_DETAIL,
  SET_MULTIPAGE,
  SET_TEMPLATE,
  SET_PAGE_CONTROLS_ENABLED,
  DO_API_CLOSE_WINDOW,
  SET_SELECTED_FORMAT_AND_TYPE,
  CLIENT_MODE,
  SET_PERMISSION,
} from "./type";
import settings from "../../config/setting";

export const authForDemo = () => (dispatch) => {
  var obj = {};
  obj.params = {
    tokenDefinitivo: null,
    actionToDo: "new",
    id_client: 10, //mettere 39 quando si effettua il test
    id_signboard: null, //mettere 40 quando si effettua il test
    id_format: 13,
    formatName: "Volantino A4",
    id_template: null,
    tab_id: null,
    is_multipage: true,
    permissionPacK: null,
    workname: "",
    demo: true,
    has_works: true,
  };
  dispatch({
    type: INIT_PARAMS,
    payload: obj,
  });
};

export const activateClientMode = (response) => (dispatch) => {
  dispatch({
    type: CLIENT_MODE,
    payload: response,
  });
};

export const authForInsertModels = () => (dispatch) => {
  var obj = {};
  obj.params = {
    tokenDefinitivo: null,
    actionToDo: "new",
    id_client: null,
    id_signboard: null,
    id_format: null,
    id_template: null,
    tab_id: null,
    is_multipage: true,
    permissionPacK: null,
    workname: "",
  };
  dispatch({
    type: INIT_PARAMS,
    payload: obj,
  });
};

export const setPageControlsEnabled = (value) => (dispatch) => {
  dispatch({
    type: SET_PAGE_CONTROLS_ENABLED,
    payload: value,
  });
};

export const setPermission = (value) => (dispatch) => {
  dispatch({
    type: SET_PERMISSION,
    payload: value,
  });
};

export const setMultipage = (value) => (dispatch) => {
  dispatch({
    type: SET_MULTIPAGE,
    payload: value,
  });
};

export const setSelectedTemplate = (item) => (dispatch) => {
  dispatch({
    type: SET_TEMPLATE,
    payload: item,
  });
};

export const authWithToken =
  (token, platform) =>
  (callback = null) =>
  (dispatch) => {
    if (!token) {
      dispatch({
        type: ERROR,
        payload: "Token non valido!",
      });
      return;
    }
    if (platform == settings.PLATFORMS.FAIDATE) {
      var url = settings.FAIDATE_URL + "auth?token=" + token;
    } else {
      var url = settings.INTERATTIVO_URL + "auth?token=" + token;
    }

    var data = new FormData();
    data.append("token", token);
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: INIT_PARAMS,
            payload: response,
            platform: platform,
          });
          callback(response);
        } else {
          dispatch({
            type: ERROR,
            payload: "Oops... Sembra che il tuo token non sia più valido!",
          });
        }
      })
      .catch((error) => {
        console.log("auth?token => ERROR", error);
        dispatch({
          type: ERROR,
          payload: error,
        });
      });
  };

export const getTemplateDetail =
  (id_template) =>
  (callback = null) =>
  (dispatch) => {
    var url =
      settings.SERVER_URL + "get-template-detail/?id_template=" + id_template;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: GET_TEMPLATE_DETAIL,
            payload: response,
          });
          callback(response);
        }
      })
      .catch((error) => {
        console.log("get-template-detail => ERROR", error);
        dispatch({
          type: ERROR,
          payload: error,
        });
      });
  };

export const getFormatDetail =
  (id_format) =>
  (callback = null) =>
  (dispatch) => {
    var url = settings.SERVER_URL + "get-format-detail/?id_format=" + id_format;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: GET_FORMAT_DETAIL,
            payload: response,
          });
          callback(response);
        }
      })
      .catch((error) => {
        console.log("get-format-detail => ERROR", error);
        dispatch({
          type: ERROR,
          payload: error.response,
        });
      });
  };

export const getTemplateDetailToUpdatePage =
  (id_template) =>
  (callback = null) =>
  (dispatch) => {
    var url =
      settings.SERVER_URL + "get-template-detail/?id_template=" + id_template;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: GET_TEMPLATE_DETAIL_TO_UPDATE_PAGE,
            payload: response,
          });
          callback(response);
        }
      })
      .catch((error) => {
        console.log("get-template-detail => ERROR", error);
        dispatch({
          type: ERROR,
          payload: error,
        });
      });
  };

export const getPageDetailToUpdatePage =
  (id_template) =>
  (page_index) =>
  (callback = null) =>
  (dispatch) => {
    var url =
      settings.SERVER_URL +
      "get-page-detail/?id_template=" +
      id_template +
      "&page_index=" +
      page_index;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: GET_PAGE_DETAIL,
            payload: response.data,
          });
          callback(response);
        }
      })
      .catch((error) => {
        console.log("get-template-detail => ERROR", error);
        dispatch({
          type: ERROR,
          payload: error,
        });
      });
  };

export const getTemplateDetailToAddPage =
  (id_template) =>
  (callback = null) =>
  (dispatch) => {
    var url =
      settings.SERVER_URL + "get-template-detail/?id_template=" + id_template;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: GET_TEMPLATE_DETAIL_TO_ADD_PAGE,
            payload: response,
          });
          callback(response);
        }
      })
      .catch((error) => {
        console.log("get-template-detail => ERROR", error);
        dispatch({
          type: ERROR,
          payload: error,
        });
      });
  };

export const postSaveTemplate = (response) => (dispatch) => {
  dispatch({
    type: POST_SAVE_TEMPLATE,
    payload: response,
  });
};

export const setLoadingApp = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_LOADING_APP,
    });
  } else {
    dispatch({
      type: END_LOADING_APP,
    });
  }
};

export const setWorkname = (text) => (dispatch) => {
  dispatch({
    type: SET_WORKNAME,
    payload: text,
  });
};

export const setShowModal = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_MODAL,
    payload: value,
  });
};

export const setDoApiCloseWindow = (value) => (dispatch) => {
  dispatch({
    type: DO_API_CLOSE_WINDOW,
    payload: value,
  });
};

export const setSelectedFormatAndType = (item) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FORMAT_AND_TYPE,
    payload: item,
  });
};
