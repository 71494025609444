const prodSettings = {
  SERVER_URL: "https://ingraph-api.interattivo.net/api/",
  SUPPORT_URL:
    "https://supportinterattivo.zendesk.com/hc/it/articles/4407895004305-Introduzione-InDesigner-per-l-Indice",
  INTERATTIVO_URL: "https://inpublish.interattivo.net/it/api/polotno/",
  FAIDATE_URL: "https://inpublish.interattivo.net/faidate/api/polotno/",
  INPUBLISH_REDIRECT: "http://inpublish.interattivo.net/",
  FLYER_BUILDER_REDIRECT: "http://inpublish.interattivo.net/",
  IMAGE_URL: "https://ingraph-api.interattivo.net/",
  IMAGE_URL_AWS: "https://ingraph-api-prod.s3.amazonaws.com/",
  //URL_PORTAL: "https://portal.interattivo.net/api/app/",
  URL_PORTAL: "https://app.inpublish.it/api/app/",
  MAX_UPLOAD_FILE_SIZE: 30,
  POLOTNO_KEY: "Mzz-4Xc4nKuMg4bSt21f",
  THEME_COLORS: {
    PRIMARY: "#007BFF",
    SECONDARY: "#fc3",
  },
  PLATFORMS: {
    INPUBLISH: "inpublish",
    FAIDATE: "faidate",
  },
};
export default prodSettings;
