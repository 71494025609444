export const INIT_LOADING_CLIENT_TEMPLATES ="INIT_LOADING_CLIENT_TEMPLATES"
export const END_LOADING_CLIENT_TEMPLATES = "END_LOADING_CLIENT_TEMPLATES"
export const INIT_REFRESH_CLIENT_TEMPLATES = "INIT_REFRESH_CLIENT_TEMPLATES"
export const END_REFRESH_CLIENT_TEMPLATES = "END_REFRESH_CLIENT_TEMPLATES"
export const INIT_LOADING_CLIENT_MODELS = "INIT_LOADING_CLIENT_MODELS"
export const END_LOADING_CLIENT_MODELS = "END_LOADING_CLIENT_MODELS"
export const INIT_REFRESH_CLIENT_MODELS = "INIT_REFRESH_CLIENT_MODELS"
export const END_REFRESH_CLIENT_MODELS = "END_REFRESH_CLIENT_MODELS"

export const GET_CLIENT_TEMPLATES = "GET_CLIENT_TEMPLATES"
export const GET_CLIENT_MODELS = "GET_CLIENT_MODELS"
export const ERROR = "ERROR"