import { observer } from 'mobx-react-lite';
import { Button } from "@blueprintjs/core";
import settings from '../config/setting';
import errorImage from '../images/error.jpg';
import { useSelector } from 'react-redux';
export const Error = observer(({ store }) => {
    const { authReducer: { error, platform } } = useSelector(state => state)

    const renderButton = () => {
        switch (platform) {
            case settings.PLATFORMS.INPUBLISH:
                return (
                    <Button
                        onClick={() => window.location.assign(settings.INPUBLISH_REDIRECT)}
                        intent="danger"
                        text={`Torna su ${settings.PLATFORMS.INPUBLISH}`}
                    />
                )
            case settings.PLATFORMS.FLYERBUILDER:
                return (
                    <Button
                        onClick={() => window.location.assign(settings.FLYER_BUILDER_REDIRECT)}
                        intent="danger"
                        text={`Torna su ${settings.PLATFORMS.FLYERBUILDER}`}
                    />
                )
            default:
                break;
        }
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <div style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column"
            }}>
                <img src={errorImage} alt="error" />
                <h4>{error}</h4>
                {renderButton()}
            </div>
        </div >
    )
})