import React, { useEffect, useState, useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Toaster, Position } from "@blueprintjs/core";
import { ImagesGrid } from "polotno/side-panel";
import { getImageSize } from "polotno/utils/image";
import { getImages, saveImage, deleteImage } from "../services/services.api";
import { useSelector } from "react-redux";

// Create a Toaster instance
const toaster = Toaster.create({
  position: Position.TOP,
});

export const UploadPanel = observer(({ store }) => {
  const [images, setImages] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const scrollContainerRef = useRef(null);
  
  const {
    authReducer: { id_client, id_signboard },
  } = useSelector((state) => state);

  const load = useCallback(async (page) => {
    if (!hasMore) return;

    setLoadingMore(true);
    try {
      const newImages = await getImages(id_client, id_signboard, page);

      if (newImages.length === 0) {
        setHasMore(false);
      } else {
        setImages((prevImages) => [...prevImages, ...newImages]);
      }
    } catch (error) {
      toaster.show({ message: "Failed to load images", intent: "danger" });
    } finally {
      setLoadingMore(false);
    }
  }, [id_client, id_signboard, hasMore]);

  const handleFileInput = async (e) => {
    const { target } = e;
    setUploading(true);
    try {
      const newImages = [];
      for (const file of target.files) {
        const uploadedImage = await saveImage(file, id_client, id_signboard);
        newImages.push(uploadedImage);
      }
      setImages((prevImages) => [...newImages, ...prevImages]);

      if (scrollContainerRef.current) {
        scrollContainerRef.current.firstChild.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      toaster.show({ message: error.message, intent: "danger" });
    } finally {
      setUploading(false);
      target.value = null;
    }
  };

  useEffect(() => {
    load(page);
  }, [load, page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        !== document.documentElement.offsetHeight
        || isUploading
        || isLoadingMore
      ) {
        return;
      }
      setPage((prevPage) => prevPage + 1);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isUploading, isLoadingMore]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <label htmlFor="input-file">
          <Button
            icon="upload"
            style={{ width: "100%", marginTop: 15 }}
            onClick={() => {
              document.querySelector("#input-file")?.click();
            }}
            loading={isUploading}
          >
            Carica l'immagine
          </Button>
          <input
            type="file"
            id="input-file"
            style={{ display: "none" }}
            onChange={handleFileInput}
            multiple
          />
        </label>
      </div>
      <div ref={scrollContainerRef} style={{ flex: 1, overflowY: 'scroll' }}>
        <ImagesGrid
          images={images}
          getPreview={(image) => image.url}
          crossOrigin="anonymous"
          getCredit={(image) => (
            <div>
              <Button
                icon="trash"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (window.confirm("Sei sicuro di voler eliminare l'immagine? ")) {
                    try {
                      await deleteImage(image.id, id_client, id_signboard);
                      setImages((prevImages) => prevImages.filter(img => img.id !== image.id));
                    } catch (error) {
                      toaster.show({
                        message: "Impossibile cancellare l'immagine",
                        intent: "danger",
                      });
                    }
                  }
                }}
              ></Button>
            </div>
          )}
          onSelect={async (image, pos, element) => {
            const { url } = image;
            let { width, height } = await getImageSize(url);
            const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;

            const type = isSVG ? "svg" : "image";

            if (element && element.type === "svg" && !element.locked && type === "image") {
              element.set({ maskSrc: url });
              return;
            }

            if (element && element.type === "image" && !element.locked && type === "image") {
              element.set({ src: url });
              return;
            }

            const scale = Math.min(store.width / width, store.height / height, 1);
            width = width * scale;
            height = height * scale;

            const x = (pos?.x || store.width / 2) - width / 2;
            const y = (pos?.y || store.height / 2) - height / 2;

            store.activePage?.addElement({
              type,
              src: url,
              x,
              y,
              width,
              height,
            });
          }}
          rowsNumber={2}
          loadMore={async () => {
            if (hasMore && !isLoadingMore) {
              setPage((prevPage) => prevPage + 1);
            }
          }}
        />
      </div>
      {isLoadingMore && <h5 style={{ textAlign:"center"}}>Caricamento immagini...</h5>}
    </div>
  );
});