import { LOAD_ELEMENTS, ERROR, INIT_LOADING_ELEMENTS, END_LOADING_ELEMENTS } from './type'
const initState = {
    elements: [],
    loadingElements: true,
    error: null,
    pagination: {
        active: 1,
        last: 20
    },
    page: 1,
    pagesize: 10
}
export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case LOAD_ELEMENTS:
            return {
                ...state,
                elements: action.payload.response.data,
                // pagination: {
                //     ...state.pagination,
                //     active: action.payload.n_page,
                //     last: action.payload.response.data.pages
                // },
                // pagesize: action.payload.page_size,
                loadingElements: false
            }
        case INIT_LOADING_ELEMENTS:
            return {
                ...state,
                loadingElements: true
            }
        case END_LOADING_ELEMENTS:
            return {
                ...state,
                loadingElements: false
            }
        case ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}