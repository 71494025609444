import { ERROR, SAVE_WORK } from './type'
const initState = {
    error: ''
}
export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case SAVE_WORK:
            return {
                ...state,
            }
        case ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}