import {
  GET_TEMPLATES,
  ERROR,
  INIT_LOADING_DEFAULT_TEMPLATES,
  END_LOADING_DEFAULT_TEMPLATES,
} from "./type";
const initState = {
  loadingTemplates: true,
  error: null,
  templates: [],
  pagination: {
    active: 1,
    last: 20,
  },
  page: 1,
  pagesize: 10,
  hasMore: true,
};
export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        templates: action.payload.page === 1 ? action.payload.response.data : [...state.templates, ...action.payload.response.data],
        page: action.payload.page,
        pagesize: action.payload.pagesize,
        pagination: {
          ...state.pagination,
          active: action.payload.page,
          last: action.payload.response.pages,
        },
        hasMore: action.payload.page < action.payload.response.pages,
      };
    case INIT_LOADING_DEFAULT_TEMPLATES:
      return {
        ...state,
        loadingTemplates: true,
      };
    case END_LOADING_DEFAULT_TEMPLATES:
      return {
        ...state,
        loadingTemplates: false,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
