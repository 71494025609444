import {
  INIT_LOADING_FORMAT,
  END_LOADING_FORMAT,
  GET_FORMAT_LIST,
  GET_TEMPLATE_TYPE_LIST,
  ERROR,
  INIT_LOADING_TYPES,
  END_LOADING_TYPES,
} from "./type";
import settings from "../../config/setting";

export const getFormat = (id_template_type) => (dispatch) => {
  var url = `${settings.SERVER_URL}get-format-by-type/?id_template_type=${id_template_type}`;
  fetch(url)
    .then((response) => response.json())
    .then((response) => {
      if (response.success) {
        dispatch({
          type: GET_FORMAT_LIST,
          payload: response,
        });
      }
    })
    .catch((error) => {
      console.log("get-format-list => ERROR", error);
      dispatch({
        type: ERROR,
        payload: error,
      });
    });
};

export const getTemplateTypeList = () => (dispatch) => {
  var url = `${settings.SERVER_URL}get-template-types-list?with_formats=1`;
  fetch(url)
    .then((response) => response.json())
    .then((response) => {
      if (response.success) {
        dispatch({
          type: GET_TEMPLATE_TYPE_LIST,
          payload: response,
        });
      }
    })
    .catch((error) => {
      console.log("get-template-types-list => ERROR", error);
      dispatch({
        type: ERROR,
        payload: error,
      });
    });
};

export const setLoadingTypes = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_LOADING_TYPES,
    });
  } else {
    dispatch({
      type: END_LOADING_TYPES,
    });
  }
};

export const setLoadingFormat = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_LOADING_FORMAT,
    });
  } else {
    dispatch({
      type: END_LOADING_FORMAT,
    });
  }
};
