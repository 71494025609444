import {
    GET_CLIENT_TEMPLATES, GET_CLIENT_MODELS, ERROR,
    INIT_LOADING_CLIENT_TEMPLATES, END_LOADING_CLIENT_TEMPLATES,
    INIT_LOADING_CLIENT_MODELS, END_LOADING_CLIENT_MODELS,
    INIT_REFRESH_CLIENT_MODELS, END_REFRESH_CLIENT_MODELS,
    INIT_REFRESH_CLIENT_TEMPLATES, END_REFRESH_CLIENT_TEMPLATES
} from './type'
const initState = {
    loadingClientTemplates: true,
    loadingClientModels: true,
    refreshClientTemplates: false,
    refreshClientModels: false,
    error: null,
    templates: [],
    models: [],
    pagination_m: {
        active: 1,
        last: 20
    },
    page_m: 1,
    pagesize_m: 10,
    pagination_t: {
        active: 1,
        last: 20
    },
    page_t: 1,
    pagesize_t: 10
}
export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case GET_CLIENT_TEMPLATES:
            return {
                ...state,
                templates: action.payload.response.data,
                page_t: action.payload.page,
                pagesize_t: action.payload.pagesize,
                pagination_t: {
                    ...state.pagination_t,
                    active: action.payload.page,
                    last: action.payload.response.pages
                }
            }
        case GET_CLIENT_MODELS:
            return {
                ...state,
                models: action.payload.response.data,
                page_m: action.payload.page,
                pagesize_m: action.payload.pagesize,
                pagination_m: {
                    ...state.pagination_m,
                    active: action.payload.page,
                    last: action.payload.response.pages
                }
            }
        case INIT_LOADING_CLIENT_TEMPLATES:
            return {
                ...state,
                loadingClientTemplates: true
            }
        case END_LOADING_CLIENT_TEMPLATES:
            return {
                ...state,
                loadingClientTemplates: false
            }
        case INIT_LOADING_CLIENT_MODELS:
            return {
                ...state,
                loadingClientModels: true
            }
        case END_LOADING_CLIENT_MODELS:
            return {
                ...state,
                loadingClientModels: false
            }
        case INIT_REFRESH_CLIENT_TEMPLATES:
            return {
                ...state,
                refreshClientTemplates: true
            }
        case END_REFRESH_CLIENT_TEMPLATES:
            return {
                ...state,
                refreshClientTemplates: false
            }
        case INIT_REFRESH_CLIENT_MODELS:
            return {
                ...state,
                refreshClientModels: true
            }
        case END_REFRESH_CLIENT_MODELS:
            return {
                ...state,
                refreshClientModels: false
            }
        case ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}