import {
  GET_TEMPLATES,
  INIT_LOADING_DEFAULT_TEMPLATES,
  END_LOADING_DEFAULT_TEMPLATES,
} from "./type";
import settings from "../../config/setting";

export const getDefaultTemplates =
  (id_format, pagesize, page, pagination, term) => async (dispatch) => {
    try {
      const url = settings.SERVER_URL + "get-interattivo-templates/";
      const data = new FormData();
      if (id_format) data.append("id_format", id_format);
      data.append("page", page);
      data.append("limit", pagesize);
      data.append("term", term);

      const response = await fetch(url, {
        method: "POST",
        body: data,
      });

      const result = await response.json();

      if (result.success) {
        dispatch({
          type: GET_TEMPLATES,
          payload: {
            response: result,
            page,
            pagesize,
            pagination,
          },
        });
        dispatch({
          type: END_LOADING_DEFAULT_TEMPLATES,
        });
      }
    } catch (error) {
      console.error("Failed to fetch templates:", error);
      // Gestisci l'errore se necessario
    }
  };

export const searchDefaultTemplatesByName =
  (term, pagesize, page, pagination) => (dispatch) => {
    dispatch({
      type: INIT_LOADING_DEFAULT_TEMPLATES,
    });
    var url = settings.SERVER_URL + "search-interattivo-templates-by-name/";
    var data = new FormData();
    data.append("term", term);
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: GET_TEMPLATES,
            payload: {
              response,
            },
          });
          dispatch({
            type: END_LOADING_DEFAULT_TEMPLATES,
          });
        } else {
          dispatch({
            type: GET_TEMPLATES,
            payload: [],
          });
          dispatch({
            type: END_LOADING_DEFAULT_TEMPLATES,
          });
        }
      });
  };

export const setLoadingInterattivoTemplates = (value) => (dispatch) => {
  if (value) {
    dispatch({
      type: INIT_LOADING_DEFAULT_TEMPLATES,
    });
  } else {
    dispatch({
      type: END_LOADING_DEFAULT_TEMPLATES,
    });
  }
};
