import settings from "../config/setting";

const delay = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms));

const MAX_IMAGE_SIZE = 2.5 * 1024 * 1024; // 2.5 MB

export async function getImages(id_client, id_signboard, page = 1) {
  try {
    //Error 1003 = id_client mancante
    if (!id_client) {
      throw new Error("Error 1003");
    }

    //Error 1004 = id_signboard mancante
    if (!id_signboard) {
      throw new Error("Error 1004");
    }

    await delay();

    const formData = new FormData();
    formData.append("type", "graph");
    formData.append("id_client", id_client);
    formData.append("id_signboard", id_signboard);
    formData.append("page", page);

    const response = await fetch(`${settings.URL_PORTAL}get-media`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) throw new Error("Failed to fetch images");
    const images = await response.json();

    if (images) {
      const { data } = images;
      return data;
    }
    return [];
  } catch (error) {
    console.error("Error getting images:", error);
    throw error;
  }
}

export async function saveImage(file, id_client, id_signboard) {
  try {
    if (file.size > MAX_IMAGE_SIZE) {
      throw new Error("Image size exceeds the maximum limit of 2.5 MB");
    }

    //Error 1003 = id_client mancante
    if (!id_client) {
      throw new Error("Error 1003");
    }

    //Error 1004 = id_signboard mancante
    if (!id_signboard) {
      throw new Error("Error 1004");
    }

    // delay to emulate network request
    await delay();

    // Save image to server
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "graph");
    formData.append("id_client", id_client);
    formData.append("id_signboard", id_signboard);
    const response = await fetch(`${settings.URL_PORTAL}add-media`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) throw new Error("Failed to upload image");
    const newImage = await response.json();
    if (newImage) {
      const { data } = newImage;
      return data;
    }
    return [];

    // const images = JSON.parse(localStorage.getItem("images") || "[]");
    // images.push({
    //   id: Date.now().toString(),
    //   url: "https://fastly.picsum.photos/id/947/1000/1000.jpg?hmac=7TrBcybpd3TKLGiNwjRm_ZKtNs7eZocUzYutDo_c3H4",
    // });
    // localStorage.setItem("images", JSON.stringify(images));
    // return images;
  } catch (error) {
    console.error("Error saving image:", error);
    throw error;
  }
}

export async function deleteImage(id, id_client, id_signboard) {
  try {
    // delay to emulate network request
    await delay();

    //Error 1003 = id_client mancante
    if (!id_client) {
      throw new Error("Error 1003");
    }

    //Error 1004 = id_signboard mancante
    if (!id_signboard) {
      throw new Error("Error 1004");
    }

    // Delete image from server
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", "graph");
    formData.append("id_client", id_client);
    formData.append("id_signboard", id_signboard);
    const response = await fetch(`${settings.URL_PORTAL}delete-media`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) throw new Error("Failed to delete image");

    // const images = JSON.parse(localStorage.getItem("images") || "[]");
    // const newImages = images.filter((image) => image.id !== id);
    // localStorage.setItem("images", JSON.stringify(newImages));
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
}
