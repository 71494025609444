import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import useImage from "use-image";
import { unstable_setImageLoaderHook } from "polotno/config";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import { Callout, Button, Dialog } from "@blueprintjs/core";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCategories,
  loadImages,
  setCategory,
  loadSubCategories,
  loadElements,
} from "../redux/prodottiReducer/action";
import { nanoid } from "nanoid";
import Modal from "react-bootstrap/Modal";
import settings from "../config/setting";
export const ProdottiPanel = observer(({ store }) => {
  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState(-1);
  const [isLoadingMore, setLoadingMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFetchingRef = useRef(false);
  const urlZenDesk =
    "https://supportinterattivo.zendesk.com/hc/it/articles/4410675564561-Creazione-Modifica-Prodotti-in-Archivio";

  const handleClose = () => setShow(false);
  const handleShow = (prod) => {
    setSelectedProduct(prod);
    setShow(true);
  };
  const dispatch = useDispatch();
  const {
    prodottiReducer: {
      images,
      primaryCategories,
      allCategories,
      elements,
      loadingElements,
      subCategories,
      loadingImages,
      pagination,
      pagesize,
      page,
      term,
      id_category,
      paginationElements,
      pagesizeElements,
      pageElements,
      hasMore,
    },
  } = useSelector((state) => state);
  const {
    authReducer: { id_client, id_signboard },
  } = useSelector((state) => state);
  const LoadImage = (url) => {
    return useImage(url, "anonymous");
  };

  unstable_setImageLoaderHook(LoadImage);

  React.useEffect(() => {
    dispatch(
      loadImages(
        id_client,
        id_signboard,
        pagesize,
        currentPage,
        term,
        id_category
      )
    );
    dispatch(loadCategories(id_client, id_signboard));
    dispatch(loadElements(pagesizeElements, pageElements));
  }, []);

  React.useEffect(() => {
    if (
      currentPage <= pagination.last &&
      isLoadingMore &&
      !isFetchingRef.current
    ) {
      isFetchingRef.current = true;
      dispatch(
        loadImages(
          id_client,
          id_signboard,
          pagesize,
          currentPage,
          term,
          id_category
        )
      ).finally(() => {
        isFetchingRef.current = false;
        setLoadingMore(false);
      });
    }
  }, [
    id_client,
    id_signboard,
    isLoadingMore,
    currentPage,
    dispatch,
    term,
    id_category,
    pagesize,
    pagination,
  ]);

  const goTofirstPage = () => {
    dispatch(
      loadImages(id_client, id_signboard, pagesize, 1, term, id_category)
    );
  };

  const goTolastPage = () => {
    dispatch(
      loadImages(
        id_client,
        id_signboard,
        pagesize,
        parseInt(pagination.last),
        term,
        id_category
      )
    );
  };

  const nextPage = () => {
    if (pagination.active !== pagination.last) {
      dispatch(
        loadImages(
          id_client,
          id_signboard,
          pagesize,
          parseInt(pagination.active) + 1,
          term,
          id_category
        )
      );
    }
  };

  const prevPage = () => {
    if (pagination.active !== 1) {
      dispatch(
        loadImages(
          id_client,
          id_signboard,
          pagesize,
          parseInt(pagination.active) - 1,
          term,
          id_category
        )
      );
    }
  };

  const goToPage = (page) => {
    dispatch(
      loadImages(id_client, id_signboard, pagesize, page, term, id_category)
    );
  };

  // metodi Paginazione elementi customizzati
  const goTofirstPageElements = () => {
    dispatch(loadElements(pagesizeElements, 1));
  };

  const goTolastPageElements = () => {
    dispatch(loadElements(pagesizeElements, parseInt(paginationElements.last)));
  };

  const nextPageElements = () => {
    if (paginationElements.active !== paginationElements.last) {
      dispatch(
        loadElements(pagesizeElements, parseInt(paginationElements.active) + 1)
      );
    }
  };

  const prevPageElements = () => {
    if (paginationElements.active !== 1) {
      dispatch(
        loadElements(pagesizeElements, parseInt(paginationElements.active) - 1)
      );
    }
  };

  const goToPageElements = (page) => {
    dispatch(loadElements(pagesizeElements, page));
  };

  const filterProducts = (category_id) => {
    setSelectedSubCategories(category_id);
    var appSubCategories = [];
    allCategories.forEach((item) => {
      if (item.id_parent === category_id) {
        appSubCategories.push(item);
      }
    });
    if (appSubCategories.length > 0) {
      dispatch(loadSubCategories(appSubCategories, category_id));
    } else {
      dispatch(setCategory(category_id));
      dispatch(
        loadImages(id_client, id_signboard, pagesize, 1, term, category_id)
      );
    }
  };

  const renderCategories = () => {
    var items = [];
    primaryCategories.forEach((category) => {
      items.push(
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });
    return items;
  };

  const renderSubCategories = () => {
    var items = [];
    subCategories.forEach((category) => {
      items.push(<option value={category.id}>{category.name}</option>);
    });
    return items;
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className="bp4-input-group .modifier" style={{ marginBottom: 10 }}>
        <span className="bp4-icon bp4-icon-search"></span>
        <input
          type="text"
          className="bp4-input"
          placeholder="Ricerca per nome..."
          onChange={(e) =>
            dispatch(
              loadImages(id_client, id_signboard, pagesize, 1, e.target.value)
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => filterProducts(e.target.value)}
          style={{ marginRight: "8px", marginBottom: "10px" }}
        >
          <option value="-1">Categorie</option>
          {renderCategories()}
        </Form.Select>
        <Form.Select
          value={selectedSubCategories}
          aria-label="Default select example"
          onChange={(e) => filterProducts(e.target.value)}
          style={{ marginLeft: "8px", marginBottom: "10px" }}
        >
          <option value="-1">Sottocategorie</option>
          {renderSubCategories()}
        </Form.Select>
      </div>

      <ImagesGrid
        shadowEnabled={true}
        crossOrigin={"anonymous"}
        images={images.map((image) => ({
          ...image,
          key: image.id + nanoid(), // Assicurati che ogni chiave sia unica
        }))}
        getPreview={(image) => `${image.url}?`}
        onSelect={async (prod) => {
          handleShow(prod);
        }}
        rowsNumber={2}
        isLoading={loadingImages}
        loadMore={async () => {
          if (hasMore && !isLoadingMore && currentPage < pagination.last) {
            setLoadingMore(true);
            setCurrentPage((currentPage) => currentPage + 1);
          }
        }}
      />
      {isLoadingMore && (
        <h5 style={{ textAlign: "center" }}>Caricamento prodotti...</h5>
      )}
      {!loadingImages && images.length == 0 && (
        <>
          <Callout
            title={
              "Attualmente non è presente nessun prodotto nel tuo archivio."
            }
            intent={"primary"}
          >
            Vuoi inserire un nuovo prodotto in archivio? Scopri come fare
            <p>
              <Button
                mninimal={true}
                text="Clicca qui"
                intent="primary"
                icon="link"
                style={{ marginTop: 10 }}
                onClick={handleButtonClick}
              />
            </p>
          </Callout>
          <Dialog
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Come inserire un nuovo prodotto"
            style={{ width: "60vw" }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <div
                className="bp3-dialog-body"
                style={{
                  position: "relative",
                  display: "block",
                  width: "100%",
                  padding: 0,
                  overflow: "hidden",
                  paddingTop: "56.25%",
                  margin: 0,
                }}
              >
                <iframe
                  style={{ position: "absolute", top: 0, left: 0 }}
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/N6Z-GjqjAOE?autoplay=1&rel=0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="bp3-dialog-footer">
              <Button text="Chiudi" onClick={closeModal} />
            </div>
          </Dialog>
        </>
      )}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Pagination>
          <Pagination.First onClick={() => goTofirstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {parseInt(pagination.active) === 1 ? (
            <Pagination.Ellipsis />
          ) : (
            <Pagination.Item
              onClick={() => goToPage(parseInt(pagination.active) - 1)}
            >
              {parseInt(pagination.active) - 1}
            </Pagination.Item>
          )}
          <Pagination.Item active>
            {parseInt(pagination.active)}
          </Pagination.Item>
          {parseInt(pagination.active) === parseInt(pagination.last) ? (
            <Pagination.Ellipsis />
          ) : (
            <Pagination.Item
              onClick={() => goToPage(parseInt(pagination.active) + 1)}
            >
              {parseInt(pagination.active) + 1}
            </Pagination.Item>
          )}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => goTolastPage()} />
        </Pagination>
      </div> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Elementi Interattivi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImagesGrid
            shadowEnabled={true}
            crossOrigin={"anonymous"}
            images={elements}
            getPreview={(element) => `${element.image}?`}
            onSelect={async (e) => {
              var selected = [];
              if (e) {
                var res = await fetch(
                  `${settings.SERVER_URL}get-element-json/${e.id}`
                );
                var elem = await res.json();
                elem.forEach((item) => {
                  item.id = nanoid(10);
                  if (item.type === "image") {
                    item.src = `${selectedProduct.url}?`;
                  }
                  if (item.type === "text") {
                    if (item.text === "price")
                      item.text =
                        selectedProduct[item.text] !== ""
                          ? `€ ${selectedProduct[item.text]}`
                          : `€ ${selectedProduct.offer_price}`;
                    else item.text = selectedProduct[item.text];
                  }
                  store.activePage.addElement(item);
                  selected.push(item.id);
                  store.selectElements(selected);
                });
                handleClose();
              }
            }}
            rowsNumber={3}
            isLoading={loadingElements}
          />
        </Modal.Body>
        <Modal.Footer>
          <Pagination>
            <Pagination.First onClick={() => goTofirstPageElements()} />
            <Pagination.Prev onClick={() => prevPageElements()} />
            {parseInt(paginationElements.active) === 1 ? (
              <Pagination.Ellipsis />
            ) : (
              <Pagination.Item
                onClick={() =>
                  goToPageElements(parseInt(paginationElements.active) - 1)
                }
              >
                {parseInt(paginationElements.active) - 1}
              </Pagination.Item>
            )}
            <Pagination.Item active>
              {parseInt(paginationElements.active)}
            </Pagination.Item>
            {parseInt(paginationElements.active) ===
            parseInt(paginationElements.last) ? (
              <Pagination.Ellipsis />
            ) : (
              <Pagination.Item
                onClick={() =>
                  goToPageElements(parseInt(paginationElements.active) + 1)
                }
              >
                {parseInt(paginationElements.active) + 1}
              </Pagination.Item>
            )}
            <Pagination.Next onClick={() => nextPageElements()} />
            <Pagination.Last onClick={() => goTolastPageElements()} />
          </Pagination>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
